import { X } from "lucide-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ApplogoLight from "../../assets/applogo-light.svg";
import { ROUTES } from "../../routes/const";
import Logo from '../../assets/applogo-light.svg'
import { IconMenu } from "@tabler/icons-react";

// set a default prop for inView
interface LandingNavProps {
  inView: boolean;
}

const LandingNav = ({ inView = true }: LandingNavProps) => {
  const [visibility, setVisibility] = useState(false);

  const handleNavbarToggler = () => setVisibility(!visibility);

  // active link on click
  useEffect(() => {
    // set active link on page load
    const navLinks = document.querySelectorAll("nav ul li a");

    navLinks.forEach((link) => {
      // remove active class from all links
      link.classList.remove("text-green-500");
      // add active class to current link
      if ((link as HTMLAnchorElement).href === window.location.href) {
        link.classList.add("text-green-500");
      }
    });
  }, []);

  return (
    <nav
      className={`${
        inView ? "text-black" : "lg:relative lg:bg-transparent"
      } top-0 fixed left-0 w-full bg-white z-50`}
      id="landing-nav"
    >
      <div
        className={`flex py-5 items-center justify-between container mx-auto px-3 ${
          inView ? "" : "lg:px-0"
        } `}
      >
        <Link to="/" className="text-3xl font-bold">
          <img src={!inView ? ApplogoLight : ApplogoLight} width={110} alt="" />
        </Link>

        {/* desktop links */}
        <div
          className="flex
          items-center"
        >
          <ul
            className={`font-light ${
              inView ? "text-gray-500" : "text-black"
            } hidden lg:flex`}
          >
            <li>
              <Link
                to={`${ROUTES.landing}pricing`}
                className="ml-5 hover:opacity-75 text-lg Hero"
              >
                Pricing
              </Link>
              <Link
                to={`${ROUTES.landing}about`}
                className="ml-5 hover:opacity-75 text-lg Hero"
              >
                About
              </Link>
              <Link
                to={`${ROUTES.landing}features`}
                className="ml-5 hover:opacity-75 text-lg Hero"
              >
                Features
              </Link>
              <Link
                to={`${ROUTES.landing}contact`}
                className="ml-5 hover:opacity-75 text-lg Hero"
              >
                Contact
              </Link>
              {/* <Link
                to={ROUTES.login}
                className={`btn ml-5 transition ease-out duration-300 font-normal px-10 py-3 rounded-full border-3 border-white ${
                  inView
                    ? "hover:bg-gray-100 hover:text-green-500 bg-green-500 text-white"
                    : "hover:bg-green-500 hover:text-white bg-gray-100 text-green-500"
                }`}
              >
                Signup
              </Link> */}
            </li>
          </ul>
          <IconMenu
            className="lg:hidden bg-white p-1 text-black-500 cursor-pointer rounded-lg hover:bg-gray-500 hover:text-white transition ease-out duration-300"
            size={40}
            onClick={handleNavbarToggler}
          />
        </div>
      </div>

      {/* mobile links */}
      <div
        className={`fixed top-0 left-0 w-full bg-white z-40 min-h-screen lg:hidden text-black-500 transform ${
          visibility ? "-translate-y-0" : "-translate-y-full"
        } transition ease-out duration-300`}
      >
        <div className="px-3 py-5 w-full">
          <div className="flex justify-between mb-10 border-b-2 border-gray-100 py-5">
            <Link to="/" className="text-2xl font-bold">
              <img
                src={Logo}
                style={{ width: "110px", height: "auto" }}
                alt="Logo"
              />
            </Link>
            <X
              className="lg:hidden bg-white p-1 text-black-500 cursor-pointer rounded-lg hover:bg-gray-500 hover:text-white transition ease-out duration-300"
              size={40}
              onClick={handleNavbarToggler}
            />
          </div>
          {/* links */}
          <ul>
            <li className="text-xl font-medium mb-5 hover:opacity-75">
              <Link
                to="/pricing"
                onClick={handleNavbarToggler}
                className="Hero block"
              >
                Pricing
              </Link>
            </li>
            <li className="text-xl font-medium mb-5 hover:opacity-75">
              <Link to="/about" onClick={handleNavbarToggler} className="Hero">
                About
              </Link>
            </li>
            <li className="text-xl font-medium mb-5 hover:opacity-75">
              <Link
                to="/features"
                onClick={handleNavbarToggler}
                className="Hero"
              >
                Features
              </Link>
            </li>
            <li className="text-xl font-medium mb-5 hover:opacity-75">
              <Link
                to="/contact"
                onClick={handleNavbarToggler}
                className="Hero"
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default LandingNav;
