import React, { useState } from 'react'
import { Badge, Button, Group, Paper, Text, Title } from '@mantine/core';
import { formatCurrency } from '../../utils/currencyFormat';
import { IconCalendarCancel, IconFileExcel, IconFilter, IconRotate } from '@tabler/icons-react';
import { handleExport } from '../../services/core-api';
import { useTransactionDataContext } from '../../context/TransactionsContext';
import { DatePicker } from 'antd';
import notify from '../../utils/notify';
import TransactionsTable from '../../components/dashboard/TransactionsTable';


export default function Withdrawals() {
  const [search, setSearch] = useState('');
  const [isloading, setIsloading]= useState(false)
  const { withdrawalTransactions, loadMoreWithdrawals, filterByDate, loadingRefresh, refreshTransactions, loadingDateFilter} = useTransactionDataContext()
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
const [transactions, setTransactions] = useState<any[]>(withdrawalTransactions)



  interface Transaction {
    id: string;
    customer: string;
    amount: string;
    accountNumber: string;
    date: string;
    agentName: string;
    agentID: string;
    transactionsMethod: string;
    agentEmail: string;
    phone: string;
    createdAt: any;
    approved: boolean;
    transactionType: string;
  }

  const filterData = async() => {
    let filtered = transactions;


    if (search) {
      filtered = filtered.filter(trans =>
        trans.accountNumber.toLowerCase().includes(search.toLowerCase())
      );
    }
  
    if (!startDate && !endDate) {
      return setTransactions(withdrawalTransactions);
    }

  if (startDate && endDate) {
    try {
      const data: Transaction[] = await filterByDate('Withdrawal', startDate, endDate);
      setTransactions(data);
      return; 
    } catch (error) {
      console.error('Error filtering transactions:', error);
    }
  }
    // if (startDate && endDate) {
    //   const start = new Date(startDate);
    //   const end = new Date(endDate);
    //   end.setHours(23, 59, 59, 999);       
    //   filtered = filtered.filter(trans => {
    //     const transactionDate = new Date(trans.createdAt.seconds * 1000);
    //     return transactionDate >= start && transactionDate <= end;
    //   });
    // }
    

    setTransactions(filtered);
  };
  
    
      // const filterData = ()=> {
      //  const filtered =  withdrawalTransactions?.filter(trans => trans.customer.includes(search))
      //  setWTransactions(filtered)
      // }
      const downloadData = ()=> {
        if(transactions.length <=0) {
          return notify('No data to export', 'error')
        }
        setIsloading(true)
        const cleanedTransactions = transactions.map(({ orgId, userId, ...cleanedTransaction }) => cleanedTransaction);
        handleExport(cleanedTransactions? cleanedTransactions : []).then(()=> {
        setIsloading(false)
        }).catch((err)=> {
          console.log(err)
        setIsloading(false)
        })
      }

        // Calculate the total amount of withdrawal balances
     const totalWithdrawalAmount = transactions.reduce((sum: number, trans: Transaction) => sum + parseFloat(trans.amount), 0);

     const handleLoadMoreData = async () => {
      const moreData = await loadMoreWithdrawals();
      if(moreData.length !== 0){
       setTransactions((prev)=> [...prev, ...moreData])
      }else{
      notify('You’ve reached the end! No more transactions available', 'info')
      }
 };
 
 const handleRefresh = async()=> {
  const data =  await refreshTransactions('Withdrawal')
  setTransactions(data)
}


  return (
    <>
        <Paper
      withBorder
      style={{borderColor: '#dedede'}}
      p={20}
      >
        <div  style={{
          display: "flex",
          justifyContent: "start",
          gap: 20,
          alignItems: "center",
          flexWrap: 'wrap',
        }}>

           
    <Group>
    <div>
         <Badge variant='light' color='black' mb={5} style={{ fontWeight: 'bold'}}> Total Balance</Badge>
         <Title mb={10} order={3}> {formatCurrency(totalWithdrawalAmount, 'GHS')}</Title>
         {/* <small>Total withdrawal balance</small> */}
         </div>

         <div>
         <Badge variant='light' color='black' mb={5} style={{ fontWeight: 'bold'}}> Total Count</Badge>
         <Title mb={10} order={2}> {transactions.length}</Title>

         </div>
    </Group>
      

        </div>
     

     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      </div>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20}}>
    <div style={{ display: 'flex', width: '70%', flexWrap: 'wrap', alignItems:'center'}}>
    <Button loading={isloading} loaderProps={{type: 'dots', size: 18}}  variant="outline" disabled={isloading} mr={10} color="#000" onClick={downloadData}> 
        <IconFileExcel /> &nbsp; Export to XLSx
        </Button>
        
      
        <DatePicker
         style={{ height: 35}}
         name='State date'
          width='20%'
          type='date'
          value={startDate}
          onChange={setStartDate}
          placeholder='Start Date'
          // mr={10}
        />
          <Text fw={'bold'}  m={5}>To</Text>

        <DatePicker
         style={{ height: 35}}
         name='End date'
          width='20%'
          type='date'
          value={endDate}
          onChange={setEndDate}
          placeholder='End Date'
          // mr={10}
        />

     <Button loading={loadingDateFilter} loaderProps={{type: 'dots', size: 18}} leftSection={<IconFilter size={20}/>} variant='filled' color='#000' onClick={filterData} style={{ marginInline: 10 }}>
          Filter Date
        </Button>
        </div>

     
       <div>
       <Button leftSection={<IconRotate size={20}/>} variant='outline' color='#000' loading={loadingRefresh} loaderProps={{type: 'dots', size: 18}} onClick={handleRefresh} style={{ marginInline: 10 }}>
          Refresh
        </Button>
        <Button leftSection={<IconCalendarCancel size={20}/>} variant='filled' color='#000' onClick={()=> {
          setStartDate('')
          setEndDate('')
          setTransactions(withdrawalTransactions)
        }} style={{ marginInline: 10 }}>
          Reset Filter
        </Button>
       </div>

       {/* <Button disabled variant="filled" color="#274941" onClick={()=> {}}> Withdrawal</Button> */}
      </div>

      <div className='tableOnMobile'>
    <TransactionsTable showLoadMoreButton loadMoreData={handleLoadMoreData} showPageTotalAmount={true} data={transactions} dataType='withdrawal'/>
    </div>
    </Paper>
    </>
  )
}


