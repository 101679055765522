import { Alert, Badge, Button, Divider, Loader, Modal, Select, Switch, Text, TextInput, Title } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import InputText from '../../components/InputText'
import { auth, db } from '../../firebase'
import { getDomainFromEmail } from '../../utils/getUserDomain'
import { getDoc, doc, setDoc, getDocs, deleteDoc, collection, addDoc, query, where, updateDoc } from "firebase/firestore";
import notify from '../../utils/notify'
import { useOrgInfo } from '../../context/orgContext'
import { IconArrowRight, IconChevronRight, IconInfoCircle, IconPlus, IconSelect } from '@tabler/icons-react'
import { useDisclosure } from '@mantine/hooks'
import {privacyPolicy} from '../../components/privacyPolicy'


export default function Settings() {
    const [opened, { open, close }] = useDisclosure(false);
    const [openPrivacyPolicy, setPrivacyPolicy] = useState(false);
    const [openOrgDetailsUpdate, setOrgDetailsUpdate] = useState(false);


    const [form, setForm] = useState({
        companyName: '',
        companyNumber: ''
    })

    const [isloading, setIsloading]= useState(false)
  const [savingAccType, setSavingAccType]= useState(false)
    const {companyName, companyNumber, sms, generateAccNo, orgId, customerAccountTypes, refresh} = useOrgInfo()
    const [checked, setChecked] = useState(generateAccNo);
    const [type, setType] = useState('')

    const hanleChange = (e: any)=> {
    const { name, value } = e.target;
     setForm({...form, [name]: value})
    }

    const handleAutoGenerateAccNo = async(event: any)=> {
        setChecked(event.currentTarget.checked)

      try {
        const orgInfo = {
            generateAccNo: !checked
        };

        const q = query(collection(db, 'orgInfo'), where('orgId', '==', orgId));
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
            const docId = querySnapshot.docs[0].id;
            await updateDoc(doc(db, 'orgInfo', docId), orgInfo);
            notify('Saved successfully');
        } 
        refresh(); // Refresh the organization info
    } catch (error) {
        console.error('Error saving settings:', error);
        notify('Error saving settings');
    }

    }
   
    const saveOrgInfo = async () => {
      setIsloading(true);
      if (!form.companyName || !form.companyNumber) {
          notify('Missing required fields: companyName, companyNumber');
          setIsloading(false);
          return;
      }
  
      try {
          const orgInfo = {
              companyName: form.companyName,
              companyNumber: form.companyNumber,
              orgId: orgId,
          };
  
          const q = query(collection(db, 'orgInfo'), where('orgId', '==', orgId));
          const querySnapshot = await getDocs(q);
          
          if (!querySnapshot.empty) {
              const docId = querySnapshot.docs[0].id;
              await updateDoc(doc(db, 'orgInfo', docId), orgInfo);
              notify('Organization information updated successfully');
          } else {
              // Create a new document
              const orgCollectionRef = collection(db, 'orgInfo');
              await setDoc(doc(orgCollectionRef), orgInfo);
              notify('Organization information added successfully');
          }
  
          refresh(); // Refresh the organization info
      } catch (error) {
          console.error('Error saving organization information:', error);
          notify('Error saving organization information');
      } finally {
        setIsloading(false);
        setOrgDetailsUpdate(false)
      }
  };


  const addCustomerAccountType = async () => {
    if(type === '' ){
      return notify('Field can not be empty')
    }else if(customerAccountTypes.some(accType => accType.toLowerCase() === type.toLowerCase())){
      return notify(`${type} already exist`)
    }
    setSavingAccType(true)
    try {
        const orgInfo = {
            customerAccountTypes: [...customerAccountTypes, type],
        };

        const q = query(collection(db, 'orgInfo'), where('orgId', '==', orgId));
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
            const docId = querySnapshot.docs[0].id;
            await updateDoc(doc(db, 'orgInfo', docId), orgInfo);
            notify('Account Type updated successfully');
        } else {
            // Create a new document
            const orgCollectionRef = collection(db, 'orgInfo');
            await setDoc(doc(orgCollectionRef), orgInfo);
            notify('Account type added successfully');
        }

        refresh(); // Refresh the organization info
    } catch (error) {
        console.error('Error saving organization information:', error);
        notify('Error saving organization information');
    } finally {
      setIsloading(false);
      close()
    setSavingAccType(false)
    }
};

  
useEffect(()=> {
    setChecked(generateAccNo || false)
}, [orgId, generateAccNo])

const closePrivacyPolicyModal = ()=> {
  setPrivacyPolicy(false)
}

  return ( 
   <div style={{ padding: 10}}>

      <Modal opened={openOrgDetailsUpdate} onClose={() => setOrgDetailsUpdate(false)} title={<Title order={5}>Update Organization</Title>} centered>
      <Text style={{fontSize: '14px', fontWeight:'bold', marginBottom: 6}}> Name</Text>
     <InputText height={45} mb={15} placeholder='Company Name' value={form.companyName} onChange={hanleChange} type='text' name='companyName' width='100%'/>
     <Text style={{fontSize: '14px', fontWeight:'bold'}}> Phone Number</Text>
     <InputText height={45} mb={15} placeholder='Company mobile number' value={form.companyNumber} onChange={hanleChange} type='text' name='companyNumber' width='100%' />
      <Button loading={isloading} disabled={isloading} h={45} color='#000' w={'100%'} onClick={saveOrgInfo}>Update Info</Button>
      </Modal>


     <Modal size={'100%'} opened={openPrivacyPolicy} onClose={closePrivacyPolicyModal} title={<Title order={5}>Privacy Policy</Title>} centered>
     <pre>{privacyPolicy}</pre>
      </Modal>


     <Modal opened={opened} onClose={close} title={<Title order={5}>Add Account Type</Title>} centered>
        <TextInput value={type} onChange={(e)=> setType(e.target.value)} placeholder='Enter account name' />
        <Button disabled={savingAccType} onClick={addCustomerAccountType} justify='right' mt={10} color={'#000'}>{savingAccType ? <Loader color='#000' size={16} /> : 'Save'} </Button>
      </Modal>
    
<Title order={2}>Settings</Title>
  <div style={{ backgroundColor: '#fff', padding: 20, marginTop: 10, borderBlock: '1px solid #dedede'}}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
      <div>
      <Title order={4}> Generate Account Number </Title>
      <small>The system will auto generate account number for new customers</small>
      </div>
      <Switch style={{ cursor: 'pointer'}} checked={checked}
      onChange={(event) => handleAutoGenerateAccNo(event)} color={'green'} size='lg' mt={20} label="" />
      </div>
     </div>

     <div style={{ backgroundColor: '#fff', padding: 20, marginTop: 10,  borderBlock: '1px solid #dedede'}}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
      <div>
      <Title order={4}> Customer Account Types </Title>
      <small>Add preferred customer account types that align with your business process</small>
      </div>
      <Select leftSection={<IconPlus onClick={open} style={{ backgroundColor: '#000', color: '#fff', borderRadius: 50, padding: 2}} size={18} />} data={customerAccountTypes} style={{ cursor: 'pointer'}}
        placeholder='Account type' color={'green'} size='md' mt={20} label="" />
      </div>
     </div>

     <div style={{ backgroundColor: '#fff', padding: 20, marginTop: 10,  borderBlock: '1px solid #dedede'}}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
      <div>
      <Title order={4}> Currency </Title>
      <small>Select preferred currency (GHS by default)</small>
      </div>
      <Select disabled value={'GHS'} data={['GHS']} style={{ cursor: 'pointer'}}
      onChange={(event) => handleAutoGenerateAccNo(event)} color={'green'} size='md' mt={20} label="" />
      </div>
     </div>

     <div style={{ backgroundColor: '#fff', padding: 20, marginTop: 10,  borderBlock: '1px solid #dedede'}}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
      <div>
      <Title order={4}> Appearance </Title>
      <small>Select preferred theme (light by default)</small>
      </div>
      <Select disabled value={'Light'} data={['Light', 'Dark', 'System']} style={{ cursor: 'pointer'}}
      onChange={(event) => handleAutoGenerateAccNo(event)} color={'green'} size='md' mt={20} label="" />
      </div>
     </div>

     <div style={{ backgroundColor: '#fff', padding: 30, marginTop: 10, borderBlock: '1px solid #dedede', cursor: 'pointer'}}  onClick={() => setOrgDetailsUpdate(true)}>
     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
     <div>
     <Title order={4}>Organizational Details</Title>
    {/* <small style={{ color: 'red'}}>Your agents can't operate without setting up organizational information. we recommend you provide and save your company name and phone number below if you haven't done that</small> */}
  
     <Badge variant='default' mb={10}>Organization ID: <span style={{ fontWeight: 'normal'}}>{orgId}</span> </Badge>
     <Badge variant='solid' ml={10} bg='green'  mb={10}>Phone: <span style={{ fontWeight: 'normal'}}>{companyNumber}</span> </Badge>
     <Badge variant='solid' ml={10} bg='green'  mb={10}>Name: <span style={{ fontWeight: 'normal'}}>{companyName}</span> </Badge>
     {/* <Badge variant='solid' ml={10} bg='green'  mb={10}>SMS Bundle: <span style={{ fontWeight: 'normal'}}>{sms.balance}</span> </Badge> */}
     </div>
     <IconChevronRight />
     </div>
     <div>
     </div>
     
     </div>

     <div style={{ backgroundColor: '#fff', padding: 20, marginTop: 10,  borderBlock: '1px solid #dedede', cursor: 'pointer'}} onClick={() => setPrivacyPolicy(true)}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
      <div>
      <Title order={4}>Privacy Policy </Title>
      </div>
      <IconChevronRight />
      </div>
     </div>

     <div style={{ backgroundColor: '#fff', padding: 20, marginTop: 10,  borderBlock: '1px solid #dedede'}}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
      <div>
      <Title order={4}>App Version </Title>
      <small style={{ fontWeight: 'bolder'}}>1.2.30</small>
      </div>
      </div>
     </div>
     

 
    <Alert icon={<IconInfoCircle />} color='yellow' style={{ display: 'flex', padding: 10, marginTop: 30,
              marginBottom: 20
      }}>
           <div style={{ display: 'flex'}}>
    <small style={{ fontWeight: 'bolder'}}>Note</small>
    </div>
        {/* <IconInfoCircle /> */}
      <small
           style={
            {
              textAlign: 'center',
              // fontSize: 14,
              color: '#000',
            }}>
          This app does not facilitate actual payments. It is designed for tracking and managing your transaction activities.
          </small>
      </Alert>
   </div>
  )
}
