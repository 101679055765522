import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebase'; // Ensure this path is correct
import { db } from '../firebase'; // Ensure this path is correct
import { collection, query, where, orderBy, limit, getDocs } from 'firebase/firestore';

interface transactionsType {
    id: string
    transId: string
    customer: string
    amount: string,
    accountNumber: string
    date: string,
    agentName: string,
    agentID: string,
    transactionsMethod: string
    agentEmail: string
    createdAt: any
    transactionType: string
    approved: boolean
  }
// Define the shape of the context data
interface TransactionsContextProps {
  transactions: transactionsType[];
  // loadingData: boolean;
  readRecentTransactionsData: () => void; // Function to fetch recent transactions
}

// Create the context
const TransactionsContext = createContext<TransactionsContextProps | null>(null);

// Provider component
export const RecentTransactionsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [transactions, setTransactions] = useState<transactionsType[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const user = auth.currentUser;

  const readRecentTransactionsData = React.useMemo(()=> async () => {
    if (user) {
      try {
        const idTokenResult = await user.getIdTokenResult();
        const claims = idTokenResult.claims;
  
        if (claims.admin || claims.role === 'Collaborator') {
          const q = query(
            collection(db, 'transactions'),
            where('orgId', '==', claims.orgId),
            orderBy('createdAt', 'desc'),
            limit(5)
          );
  
          const querySnapshot = await getDocs(q);
          const docsData = querySnapshot.docs.map(
            (doc) => ({ id: doc.id, ...doc.data() } as transactionsType)
          );
          setTransactions(docsData);
        }
      } catch (error) {
        console.error('Error fetching transactions:', error);
      } finally {
        // setLoadingData(false);
      }
    } else {
      console.log('No user is signed in');
      // setLoadingData(false);
    }
    
}, [user]) 
    // setLoadingData(true);
   

  
  

  // Optionally fetch data on mount
  useEffect(() => {
    readRecentTransactionsData();
  }, []);

  return (
    <TransactionsContext.Provider value={{ transactions, readRecentTransactionsData }}> 
      {children}
    </TransactionsContext.Provider>
  );
};
//loadingData
// Custom hook to use the TransactionsContext
export const useRecentTransactionsContext = () => {
  const context = useContext(TransactionsContext);
  if (!context) {
    throw new Error('useTransactionsContext must be used within a TransactionsProvider');
  }
  return context;
};
