import { Avatar, Button, Card, Paper, Title, Tabs, Text, Progress, Badge } from "@mantine/core";
import React, { useState, useEffect, useCallback } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import InputText from "../../components/InputText";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { saveProfileInfo, sendSMS, topUpSMSAfterPurchase, } from "../../services/core-api";
import { IconBrandAndroid } from "@tabler/icons-react";
import { usePaystackPayment } from 'react-paystack';
import notify from "../../utils/notify";
import { decryptValue, encryptValue } from "../../utils/encryptvalue";
import { useAdminStatusContext } from "../../context/adminContext";
import { useOrgInfo } from "../../context/orgContext";
import AppImage from '../../assets/mobileApp.png'
import { useConfig } from "../../context/keyContext";


const payConfig = (amount: number, email: string, key: string) => {
  const reference = (new Date()).getTime().toString();
  return  {
    reference,
    email:  email,
    amount: amount * 100, 
    currency: 'GHS',
    channels: ["mobile_money"],
    publicKey: key || '',
    metadata: {
      custom_fields: [
          {
              display_name: 'description',
              variable_name: 'description',
              value: 'SMS Purchase'
          }
      ]
  }
  }
}

export default function Profile() {
  const auth = getAuth();
  const [activeTab, setActiveTab] = useState<string | null>("first");
  const [loading, setLoading] = useState(false)
  // const  plan  = useTier()
  const { plan, companyNumber } = useOrgInfo()
  const [loadSMSPurchase, setLoadSMSPurchase] = useState<boolean>(false)
  const {config} = useConfig()

  const plans = [
    { basic: [
      { price: "GHS 10", amount: 10, description: "No expiry", sms: 200 },
      { price: "GHS 20", amount: 20, description: "No expiry", sms: 400},
      { price: "GHS 50", amount: 50, description: "No expiry", sms: 810 },
      { price: "GHS 60", amount: 60, description: "No expiry", sms: 1020},
    ],
    advanced: [
      { price: "GHS 100", amount: 15, description: "No expiry", sms: "200 Units" },
      { price: "GHS 200", amount: 25, description: "No expiry", sms: "500 Units" },
      { price: "GHS 300", amount: 50, description: "No expiry", sms: "910 Units" },
      { price: "GHS 400", amount: 60, description: "No expiry", sms: "1210 Units" },
    ]
  }
   
  ];
  const [image, setImage] = useState<File | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const [url, setUrl] = useState<string>('');
  const {isAdmin} = useAdminStatusContext()

  const [userInfo, setUserInfo] = useState({
    uid: auth.currentUser?.uid,
    photo: '',
    fullName: auth.currentUser?.displayName || '',
    email: auth.currentUser?.email || '',
    phone: auth.currentUser?.phoneNumber || ''
  });

  const initializePayment = usePaystackPayment(payConfig(0, '', ''));

  const onSuccess = useCallback((reference: any) => {
      const encryptedQuantity = localStorage.getItem('smsQuantity');
      if (encryptedQuantity) {
        // Decrypt the stored smsQuantity
        const smsQuantityToUse = parseInt(decryptValue(encryptedQuantity, config?.encryptKey || ''), 10);
  
        // Now you can use smsQuantityToUse in your API call
        topUpSMSAfterPurchase(auth.currentUser?.email || '', smsQuantityToUse).then((res) => {
          notify(res);
          notify(`Sms purchase ${reference.status}`)
          sendSMS(`You have Successfully purchased ${smsQuantityToUse}sms units`, [companyNumber])
        });
        localStorage.removeItem('smsQuantity');
       setLoadSMSPurchase(false)
      } else {
        console.error("No SMS quantity found");
       setLoadSMSPurchase(false)
      }
  
  }, [auth.currentUser])
  
  
  
  const onClose = () => {
    // console.log('closed')
    localStorage.removeItem('smsQuantity');
    setLoadSMSPurchase(false)
  }
  
  

  useEffect(() => {
    if (url) {
      setUserInfo((prevState) => ({
        ...prevState,
        photo: url
      }));
    }
  }, [url]);

  useEffect(()=> {
    setUrl(auth.currentUser?.photoURL || '')
  }, [])

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const selectedImage = e.target.files[0];
      setImage(selectedImage);
      setUrl(URL.createObjectURL(selectedImage));
    }
  };

  const handleUpload = () => {
    if (!image) return;
    setLoading(true)
    const storage = getStorage();
    const storageRef = ref(storage, `images/${image.name}`);
    const uploadTask = uploadBytesResumable(storageRef, image);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (error) => {
        console.error('Upload error:', error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL: any) => {
          setUrl(downloadURL);
          setLoading(false)
          saveProfileInfo( userInfo.uid, downloadURL, userInfo.fullName, userInfo.phone)
        });
      }
    );
  };

  const purchaseSms = (amount: number, smsQuantity: number) => {
    setLoadSMSPurchase(true);
    // Encrypt the smsQuantity before storing it
    const encryptedQuantity = encryptValue(smsQuantity.toString(), config?.encryptKey || '');
    localStorage.setItem('smsQuantity', encryptedQuantity);
    initializePayment({
      onSuccess,
      onClose,
      config: payConfig(amount, userInfo.email, config?.payStackKey || '')
    });
  };
  
  
 

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (image) {
      handleUpload();
    } else {
      try{
        setLoading(true)
      const response: any =  await saveProfileInfo(userInfo.uid, userInfo.photo, userInfo.fullName, userInfo.phone)
       if(response.status === 200){
        notify("Profile updated successfully")
       }else {
        notify("Error updating profile please try later")
       }
      setLoading(false)
      }
      catch(err){
       alert(err)
       setLoading(false)
      }finally{
        setLoading(false)
      }
    }
  };

  const resetPassword = (email: string) => {
    setLoading(true)
    sendPasswordResetEmail(auth, email)
      .then(() => {
        notify('Email send. Please check your email to reset your password!')
    setLoading(false)
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // console.error(errorCode, errorMessage);
        alert(errorMessage);
    setLoading(false)
        // Handle errors here, e.g., invalid email, etc.
      });
  };


  ////update org info
 

  return (
    <Tabs value={activeTab} onChange={setActiveTab}>
      <Tabs.List>
        <Tabs.Tab value="first" style={{ fontWeight: "bold", fontSize: 16 }}>
        <Title order={5}>Profile</Title>
        </Tabs.Tab>
        <Tabs.Tab value="second" style={{ fontWeight: "bold", fontSize: 16 }}>
        <Title order={5}> Reset password </Title>
        </Tabs.Tab>

        <Tabs.Tab value="third" style={{ fontWeight: "bold", fontSize: 16 }}>
        <Title order={5}> Mobile app </Title> 
        </Tabs.Tab> 

        {isAdmin ?  <Tabs.Tab value="forth" style={{ fontWeight: "bold", fontSize: 16 }}>
        <Title order={5}> Extra SMS </Title>
        </Tabs.Tab> : null}

       
        
      </Tabs.List>

      <Tabs.Panel value="first">
        <div style={{ marginTop: 40 }}>
       {/* {plan &&  <Badge mb={10} color="orange" variant="light"> {plan}</Badge>} */}
          <Paper bg={'#fff'} withBorder style={{borderColor: '#dedede'}} p={40}>
            <Title order={4}>Personal Information</Title>
            <div style={{ maxWidth: '10%' }}>
              <label htmlFor="file-upload">
                <Avatar
                  style={{ cursor: 'pointer' }}
                  mt={20}
                  itemRef="file-upload"
                  src={url}
                  size={120}
                  mb={10}
                />
                <Progress color="#274941" mt={20} value={progress}/>
              </label>
              <input
                id="file-upload"
                style={{ visibility: 'hidden' }}
                type="file"
                accept="image/png, image/jpg, image/gif, image/jpeg"
                onChange={handleImageChange}
                name="photo"
              />
            </div>

            <form onSubmit={handleSubmit}>
              <InputText label="Name" type="text" name="fullName" width={"30%"} value={userInfo.fullName || ''} placeholder="Name" onChange={handleChange} />
              <InputText disabled label="Email" mt={20} type="text" name="email" width={"30%"} value={userInfo.email || ''} placeholder="Email address" onChange={handleChange} />
              <InputText label="Phone number" mt={20} type="text" name="phone" width={"30%"} value={userInfo.phone || ''} placeholder="Phone number" onChange={handleChange} />
              <Button loading={loading} disabled={loading} mt={20} color="#000" type="submit">Save profile</Button>
            </form>
          </Paper>
        </div>
      </Tabs.Panel>

     
      <Tabs.Panel value="second">
      <Paper p={20}>
         <InputText disabled label="Email" mt={20} type="text" name="email" width={"30%"} value={userInfo.email || ''} placeholder="Email address" onChange={()=> {}} />
         <Button loading={loading} disabled={loading} onClick={()=> resetPassword(auth.currentUser?.email ? auth.currentUser?.email: '')} mt={20} color="#000"> Reset password</Button>
         </Paper>
        </Tabs.Panel>
    

   
      <Tabs.Panel value="third">
      {/* <div style={{ backgroundColor: 'green', padding: 10, display: 'flex'}}>
      <IconInfoCircle style={{ color: '#fff'}}/>  <Text ml={5} c={'#fff'} fw={'bold'}> Mobile app latest version available 1.1.38</Text>
      </div> */}
      <Paper p={30}>
        <div style={{ display: 'flex', flexDirection: 'column', }}>
        <img src={AppImage} width={100} />
        <Text mt={30}>Download app for your field agents</Text>
          <Text style={{ fontSize: '13px'}} c={'dimmed'} mb={10}>Latest version: 2.0.0</Text>
          <div>
          <Button component="a" href="https://digicollect-apk.s3.amazonaws.com/DigiCollect.apk" mt={10} variant="filled" color="#000"><IconBrandAndroid />&nbsp; Download App</Button>
          </div>
        </div>
        </Paper>
      </Tabs.Panel>
     
      


     <Tabs.Panel value="forth">
      {/* <div style={{ backgroundColor: 'red', padding: 10, display: 'flex'}}>
      <IconInfoCircle style={{ color: '#fff'}}/>  <Text ml={5} c={'#fff'} fw={'bold'}> SMS purchase not available at the moment, please check back later</Text>
      </div> */}
      <Text mt={30} ml={20}> Purchase Non-expiry SMS</Text>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          {plans.map((plan) => (
            plan.basic.map((plan)=> {
              return (
                <Card withBorder w={300} mt={40} style={{ borderColor: '#dedede', backgroundColor: "#fff", minHeight: 350, padding: 30, margin: 20, display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
              <div>
                <Title>{plan.price}</Title>
                <Title order={3} mt={10}>{plan.sms} <small> units</small></Title>
                <p style={{ marginTop: 20}}>{plan.description}</p>
              </div>
              <Button 
              disabled={loadSMSPurchase} 
              // disabled
              onClick={() => purchaseSms(plan.amount, plan.sms)} color="#000">Buy SMS</Button>
            </Card>
              )
            })
          ))}
        </div>
      </Tabs.Panel>
    </Tabs>
  );
}
