import { jsPDF } from "jspdf";
import "jspdf-autotable";
import notify from "./notify";
import { customerProp } from "../views/dashboard/customers";
import { formatCurrency } from "./currencyFormat";
import { formatDate } from "./formatDate";

const months = [
  "January", "February", "March", "April", "May", "June", 
  "July", "August", "September", "October", "November", "December"
];


const downloadCustomerStatement = (
  customer: customerProp, 
  transactionData: any, 
  orgName: string, 
  period: "thisMonth" | "all"
) => {
  // Filter data based on the selected period
  const filteredData = transactionData.filter((data: any) => {
    if (data.reversed === true || data.approved === false) {
      return false;
    }
    const isMatchingCustomer = 
      data.accountNumber === customer.accountNumber &&
      data.customer === customer.fullName;

    if (period === "thisMonth") {
      const createdAtDate = new Date(data.date);
      const currentDate = new Date();
      return (
        isMatchingCustomer &&
        createdAtDate.getMonth() === currentDate.getMonth() &&
        createdAtDate.getFullYear() === currentDate.getFullYear()
      );
    }

    return isMatchingCustomer; // For "all" period, match customer only
  });

  if (filteredData.length === 0) {
    notify("Statement not available");
    return;
  }

  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.width;

  // Center the orgName with large, bold font for professionalism
  doc.setFont("helvetica", "bold");
  doc.setFontSize(18);
  const orgNameWidth = doc.getTextWidth(orgName);
  doc.text(`${orgName}`, (pageWidth - orgNameWidth) / 2, 20);

  // Add a line after the organization name for separation
  doc.setLineWidth(0.5);
  doc.line(20, 25, pageWidth - 20, 25);

  // Center the Transaction Statement text
  doc.setFontSize(14);
  const statementText = `Account Statement for ${period === 'thisMonth' ? months[new Date().getMonth()] : 'All Transactions'}`;
  const statementTextWidth = doc.getTextWidth(statementText);
  doc.text(statementText, (pageWidth - statementTextWidth) / 2, 35);

  // Customer Name in bold and larger font
  const customerName = `${customer.fullName}`;
  const NameWidth = doc.getTextWidth(customerName);
  doc.setFont("helvetica", "bold"); 
  doc.setFontSize(16);
  doc.text(customerName, (pageWidth - NameWidth) / 2.1, 45);

  // Add Account Balance text below customer name
  doc.setFont("helvetica", "normal");
  doc.setFontSize(12);
  const accountBalanceText = `Account Balance: ${formatCurrency(parseFloat(customer.accountBalance), 'GHS')}`;
  const accountBalanceTextWidth = doc.getTextWidth(accountBalanceText);
  doc.text(accountBalanceText, (pageWidth - accountBalanceTextWidth) / 2, 55);

  // Account Number below the account balance
  doc.setFontSize(10);
  const accountNumberText = `Account Number: ${customer.accountNumber}`;
  const accountNumberWidth = doc.getTextWidth(accountNumberText);
  doc.text(accountNumberText, (pageWidth - accountNumberWidth) / 2, 60);

  // Line break and prepare for table
  const marginBottom = 10;
  const nextYPosition = 75 + marginBottom;

  // Set up table headers with professional styling
  const headers = [
    "Transaction Date", 
    "Accounting Date", 
    "Deposit", 
    "Withdrawal", 
    "Field Agent"
  ];

  const rows = filteredData.map((data: any) => [
    formatDate(data.createdAt), // Transaction Date
    formatDate(data.updatedAt), // Accounting Date
    data.transactionType === 'Deposit' && (data.amount !== 0 && data.amount !== null) ? formatCurrency(data.amount, 'GHS') : '', // Deposit
    data.transactionType === 'Withdrawal' && (data.amount !== 0 && data.amount !== null) ? formatCurrency(data.amount, 'GHS') : '', // Withdrawal
    data.agentName // Field Agent
  ]);

  // Create table with autoTable
  doc.autoTable({
    head: [headers],
    body: rows,
    startY: nextYPosition,
    theme: 'grid',
    headStyles: {
      fillColor: [0, 51, 102],  // Dark blue header color
      textColor: [255, 255, 255],  // White text color
      fontSize: 12,
      fontStyle: 'bold',
      halign: 'center',
    },
    bodyStyles: {
      fontSize: 10,
      halign: 'center',
    },
    margin: { top: 5, left: 5, bottom: 5, right: 5 },
    styles: { cellPadding: 5, overflow: 'linebreak' }
  });

  // Add footer with page number
  const pdfDataUrl = doc.output("bloburl");

  window.open(pdfDataUrl);
};

export { downloadCustomerStatement };
