import { Mail, MapIcon, Phone } from "lucide-react";
import { Link } from "react-router-dom";
// import logo from '../assets/applogo-light.svg'

const LandingFooter = () => {
  return (
    <footer style={{ backgroundColor: '#f3f4f6', padding: 50}}>
      <div className="container mx-auto px-3 mt-20">
        <div className="flex flex-col md:flex-row md:items-center justify-between py-5 gap-y-10 md:gap-y-0">
     
          <div className="mb-0">
            <h3 className="text-2xl text-black font-bold mb-5 Hero">Get In Touch</h3>

            <ul className="text-lg text-black font-light">
              <li className="mb-3 flex gap-x-1">
                <address className="not-italic flex gap-x-1 Hero">
                  <MapIcon className="text-black" />
                   Tse-Addo (Greater-Accra)
                </address>
              </li>
              <li className="mb-3 flex gap-x-1">
                <address className="not-italic flex gap-x-1 ">
                  <Mail className="text-black" />
           
                  <Link
                    to="mailto:info@digicollect.app"
                    className="hover:text-green-500 hover:underline Hero"
                  >
                     info@digicollect.app
                  </Link>
                </address>
              </li>{" "}
              <li className="mb-3 flex gap-x-1">
                <address className="not-italic flex gap-x-1">
                  <Phone className="text-black" />
              
                  <Link
                    to="tel:+233123456789"
                    className="hover:text-green-500 hover:underline Hero"
                  >
                    +233 020 422 5765
                  </Link>
                </address>
              </li>
              <li className="mb-3 flex gap-x-1">
                <address className="not-italic flex gap-x-1">
                  <Phone className="text-black" />

                  <Link
                    to="tel:+233123456789"
                    className="hover:text-green-500 hover:underline Hero"
                  >
                    +233 025 657 8084
                  </Link>
                </address>
              </li>
            </ul>
          </div>


          <div className="">
          {/* <img src={logo} width={130}/> */}
          <p style={{ fontSize: 12, marginTop: 20}}>
            </p>
            <h3 className="text-2xl text-black font-bold mb-5 Hero">Connect with us</h3>
            <p className="Hero" style={{color: '#000'}}>X</p>
            <a  style={{color: '#000'}} href="https://www.linkedin.com/company/digicollect-app/?viewAsMember=true"className="Hero">LinkedIn</a>
            <p  style={{color: '#000'}} className="Hero">Instagram</p>
          </div>

         
        </div>
      <hr style={{marginTop: 20, border: '0.5px solid #bababa'}} />
        <div className="text-center py-5 px-3 text-xs text-gray-500 Hero">
          &copy; {new Date().getFullYear()} DigiCollect. All
          rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default LandingFooter;
