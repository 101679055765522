import React, { useEffect, useState } from "react";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes/const";
import {nanoid} from 'nanoid/non-secure';
import {
  Button,
  Paper,
  Table,
  Text,
  Title,
  RingProgress,
  Loader,
  Badge,
  Modal,
  Select,
  TextInput,
  Tabs,
  Group,
  Avatar,
} from "@mantine/core";
import {
  IconArrowDown,
  IconArrowUp,
  IconArrowUpRight,
  IconArrowsExchange,
  IconPlus,
  IconRefresh,
  IconUser,
  IconUserCircle,
} from "@tabler/icons-react";
import { formatCurrency } from "../../utils/currencyFormat";
import { formatDate } from "../../utils/formatDate";
import { AreaChart, LineChart } from "@mantine/charts";
import { useOrgInfo } from "../../context/orgContext";
import { useAdminStatusContext } from "../../context/adminContext";
import { useDailyTotalBalanceContext } from "../../context/TotalDailyTransactionAmountContext";
import { useRecentTransactionsContext } from "../../context/recentTransactionsContext";
import { useDailyTransactionsCountContext } from "../../context/TotalDailyTransactionCount";
import { useCustomerDataContext } from "../../context/customersContext";
import { useWeeklyMetricsContext } from "../../context/weeklyTransactionMetricsContext";
import { useDisclosure } from "@mantine/hooks";
import { calculateDepositBalance, calculateWithdrawalBalance, saveTransaction, sendSMS, sendSMSProp, smsDeduct } from "../../services/core-api";
import { useUser } from "../../context/UserContext";
import notify from "../../utils/notify";
import { Divider, Skeleton } from "antd";
import useCollaboratorStatus from "../../hooks/useCollaboratorStatus";
import Card from "../../components/dashboard/card";

export default function Home() {
  const { transactions, readRecentTransactionsData } =
    useRecentTransactionsContext();
  const { refreshDailyTransactions } = useDailyTransactionsCountContext();
  const { dailyTotal, refreshDailyTotal, loading } = useDailyTotalBalanceContext();
  const { refreshCustomers } = useCustomerDataContext();
  const { refresh } = useOrgInfo();
  const weeklyMetricsData = useWeeklyMetricsContext();
  const [opened, { open, close }] = useDisclosure(false);
  const { isAdmin } = useAdminStatusContext();
  const  isCollaborator  = useCollaboratorStatus()
  const { customers } = useCustomerDataContext();
  const [form, setForm] = useState({
    customerName: "",
    accountNumber: "",
    balance: 0,
    phoneNumber: "",
  });
  const [depositAmount, setDepositAmount] = useState<number>(0);
  const [withdrawalAmount, setWithdrawalAmount] = useState<number>(0);
  const [accountingDate, setAccountingDate] = useState(new Date().toLocaleDateString());
  const [activeTab, setActiveTab] = useState<string | null>("Deposit");
  const { user } = useUser();
  const transactionID = nanoid(8);
const{sms, companyName} = useOrgInfo()
const [isloadingTrans, setIsloadingTrans] = useState(false)
const [proceedPayment, setProceedPayment] = useState(false)

  const rows = transactions?.map((trans) => (
    <Table.Tr key={trans.id}>
      <Table.Td>{trans.transId}</Table.Td>
      <Table.Td>{formatDate(trans.createdAt)}</Table.Td>
      <Table.Td style={{ fontWeight: 'bold' }}>{trans.customer}</Table.Td>
      <Table.Td style={{ color: "green", fontWeight: "bold" }}>
        {trans.transactionType}
      </Table.Td>
      <Table.Td>{trans.accountNumber}</Table.Td>
      <Table.Td>{formatCurrency(parseFloat(trans.amount), "GHS")}</Table.Td>
      <Table.Td>{trans.agentName}</Table.Td>
      <Table.Td>
        {trans.approved ? (
          <Badge variant="outline" color="green" style={{ fontWeight: "bold" }}>
            Successful
          </Badge>
        ) : (
          <Badge
            variant="outline"
            color="orange"
            style={{ fontWeight: "bold" }}
          >
            Pending
          </Badge>
        )}
      </Table.Td>
    </Table.Tr>
  ));

  //calculate Deposit balance
  const calculateDepBalance = async () => {
    try {
      const response = await calculateDepositBalance({
        accountNumber: form.accountNumber,
        agentEmail: user?.email || '',
        depositAmount: depositAmount,
        accountBalance: form.balance,
      });
      return response;
    } catch (err) {
      return err;
    }
  };

  //calculate Withdrawal balance
  const smsMessage = {
    amount:
      activeTab === 'Deposit'
        ? formatCurrency(depositAmount, 'GHS')
        : formatCurrency(withdrawalAmount, 'GHS'),
    transactionType: activeTab,
    accountNumber: form.accountNumber,
    balance: form.balance,
  };
  const DepositBalanceMath = form.balance + depositAmount
  
  const message = `${companyName?.toUpperCase()} Deposit of ${
    smsMessage.amount
  } made to ACC No: ${
    smsMessage.accountNumber
  }. TID: ${transactionID}. Your balance is ${formatCurrency(DepositBalanceMath, 'GHS')}`;


  const SmsReceipt = () => {
    if (
      sms.balance <= 1 ||
      form.phoneNumber === '0000000000' ||
      form.phoneNumber.length < 10
    ) {
      return;
    } else {
      sendSMS(message.toString(), [form.phoneNumber]) ///send sms receipt
        .then(res => {
         notify('Successful, SMS Receipt sent successfully', 'success')
          smsDeduct(user?.email || '', 1);
        })
        .catch(err => {
          notify('Error: SMS receipt failed to send', 'error')
          return;
        });
    }
  };

  const SmsPendingWithdrawalSMSReceipt = () => {
     const message = `Your withdrawal request of ${formatCurrency(
        withdrawalAmount, 'GHS'
      )} is pending. TID: ${transactionID}`
 
    if (
      sms.balance <= 1 ||
      form.phoneNumber === '0000000000' ||
      form.phoneNumber.length < 10
    ) {
      return;
    } else {
      sendSMS(message, [form.phoneNumber]) ///send sms receipt
        .then(res => {
         notify('Withdrawal pending, SMS sent successfully')
         smsDeduct(user?.email || '', 1);
        })
        .catch(err => {
         notify('SMS failed to send');
          console.log(err);
          return;
        });
    }
  };

  const processTransaction = async () => { 
   if(form.customerName === ''){
    return notify('Select customer')
   }
    /// deposit
    if (activeTab === "Deposit") {
      if(depositAmount <= 0 ){
        return notify('Invalid amount')
       }
       setIsloadingTrans(true)

      const idTokenResult = await user?.getIdTokenResult();
      const customClaims = idTokenResult?.claims;
      const agentID = (customClaims?.agentId || "") as string;
      try {
        saveTransaction({
          transId: transactionID,
          customer: form.customerName,
          accountNumber: form.accountNumber,
          phone: form.phoneNumber,
          balance: form.balance,
          agentEmail: user?.email || "",
          amount: depositAmount,
          agentName: user?.displayName || "",
          agentID: agentID,
          date: new Date().toDateString(),
          transactionType: activeTab,
          approved: true,
          accountingDate: accountingDate,
        }).then(async res=> {
          if(res.requireUpgrade){
            notify(res.message)
            setIsloadingTrans(false)
            setForm({
              customerName:'',
              accountNumber: '',
              phoneNumber:'',
              balance: 0,
            });
            setProceedPayment(false)
           close()
           return
          }
          await calculateDepBalance()
          SmsReceipt()
          notify(res.message)
          setIsloadingTrans(false)
          setForm({
            customerName:'',
            accountNumber: '',
            phoneNumber:'',
            balance: 0,
          });
          setProceedPayment(false)
         close()
        })
      }catch(err) {
        notify(`${err}`)
        setIsloadingTrans(false)
        setProceedPayment(false)
        setForm({
          customerName:'',
          accountNumber: '',
          phoneNumber:'',
          balance: 0,
        });
        close()
      }
     
    }
 ///withdrawal
    if (activeTab === "Withdrawal") {
      if(withdrawalAmount <= 0 ){
        return notify('Invalid amount')
       }
       setIsloadingTrans(true)
       
      const idTokenResult = await user?.getIdTokenResult();
      const customClaims = idTokenResult?.claims;
      const agentID = (customClaims?.agentId || "") as string;

      try{
        saveTransaction({
          transId: transactionID,
          customer: form.customerName,
          accountNumber: form.accountNumber,
          phone: form.phoneNumber,
          balance: form.balance,
          agentEmail: user?.email || "",
          amount: withdrawalAmount,
          agentName: user?.displayName || "",
          agentID: agentID,
          date: new Date().toDateString(),
          transactionType: activeTab,
          approved: false,
          accountingDate: accountingDate,
        }).then(async res=> {
          if(res.requireUpgrade){
            notify(res.message)
            setIsloadingTrans(false)
            setProceedPayment(false)
            setForm({
              customerName:'',
              accountNumber: '',
              phoneNumber:'',
              balance: 0,
            });
           close() 
            return
          }
          notify(res.message)
          SmsPendingWithdrawalSMSReceipt()
          setIsloadingTrans(false)
          setProceedPayment(false)
          setForm({
            customerName:'',
            accountNumber: '',
            phoneNumber:'',
            balance: 0,
          });
         close() 
        })
      }catch(err){
         notify("Error: Can't process transaction")
         setProceedPayment(false)
        setForm({
          customerName:'',
          accountNumber: '',
          phoneNumber:'',
          balance: 0,
        });
        setIsloadingTrans(false)
        close() 
      }
    }
  };

  return (
    <div style={{ margin: "0 auto" }}>
      <Modal
        opened={opened}
        onClose={()=> {
          close()
          setForm({
            customerName:'',
            accountNumber: '',
            phoneNumber:'',
            balance: 0,
          });
          setProceedPayment(false)
      
        }}
        title={
          <Title order={5} fw={"bold"}>
            {proceedPayment ? `Confirm ${activeTab}`: 'New Transaction'}
          </Title>
        }
        centered
      >

        {proceedPayment ? 
         <div>
          <div style={{ backgroundColor: '#f9f9f7', padding: 20, borderRadius: 5}}>
          <Group justify="apart" mb="xs">
    <Text fw="bold">Customer Name:</Text>
    <Text> {form.customerName}</Text>
  </Group>
  <Group justify="apart" mb="xs">
    <Text fw="bold">Amount:</Text>
    <Text>
      {activeTab === 'Deposit'
        ? formatCurrency(depositAmount, 'GHS')
        : formatCurrency(withdrawalAmount, 'GHS')}
    </Text>
  </Group>
  <Group justify="apart" mb="xs">
    <Text fw="bold">Transaction Type:</Text>
    <Text>{activeTab}</Text>
  </Group>
  <Group justify="apart" mb="xs">
    <Text fw="bold">Account Number:</Text>
    <Text>{form.accountNumber}</Text>
  </Group>
  <Group justify="apart" mb="xs">
    <Text fw="bold">Account Balance:</Text>
    <Text>{formatCurrency(form.balance, 'GHS')}</Text>
  </Group>
  <Group justify="apart" mb="xs">
    <Text fw="bold">Phone Number:</Text>
    <Text>{form.phoneNumber}</Text>
  </Group>
          </div>
 

         <Button
          loading={isloadingTrans}
          disabled={form.customerName ? false : true}
           bg="#000"
           mt="md"
           fullWidth
           onClick={() => processTransaction()}
         >
           Confirm {activeTab}
         </Button>
       </div>:
         <Tabs p={5} value={activeTab} onChange={setActiveTab}>
         <Tabs.List>
           <Tabs.Tab leftSection={<IconArrowDown />} value="Deposit"><Title order={5}>Deposit</Title></Tabs.Tab>
           <Tabs.Tab leftSection={<IconArrowUpRight />} value="Withdrawal"><Title order={5}>Withdrawal</Title></Tabs.Tab>
         </Tabs.List>
         <Select
  required
  placeholder="Select customer"
  leftSection={<Avatar key={form?.accountNumber}
  name={form?.customerName}
  color="initials" size={30} />}
  styles={{
    input: {
      height: '40px'
    }
  }}
  mt={10}
  searchable
  data={customers.map((customer) => ({
    value: customer.accountNumber,
    label: customer.fullName,
    disabled: !customer.approved, // Disable if not approved
  }))}
  label="Select customer"
  onChange={(value) => {
    const selectedCustomer = customers.find(
      (customer) => customer?.accountNumber === value
    );
    if (selectedCustomer) {
      setForm({
        ...form,
        customerName: selectedCustomer.fullName,
        accountNumber: selectedCustomer.accountNumber,
        phoneNumber: selectedCustomer.phone,
        balance: parseFloat(selectedCustomer.accountBalance),
      });
    }
  }}
  renderOption={(value) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Avatar
        mr={5}
        key={value.option.value}
        name={value.option.label}
        color="initials"
      />
      <p>{value.option.label}</p>
    </div>
  )}
/>

         {activeTab === "Deposit" ? (
           <TextInput
           required
             mt={10}
             type="number"
             label={`${activeTab} Amount`}
             placeholder="Enter deposit amount"
             value={depositAmount}
             onChange={(e) => setDepositAmount(Number(e.target.value))}
           />
         ) : (
           <TextInput
           required
             mt={10}
              type="number"
             label={`${activeTab} Amount`}
             placeholder="Enter withdrawal amount"
             value={withdrawalAmount}
             onChange={(e) => setWithdrawalAmount(Number(e.target.value))}
           />
         )}
         <TextInput
           disabled
           mt={10}
           placeholder="Account number"
           label="Account Number"
           value={form?.accountNumber}
         />
         <TextInput
           disabled
           mt={10}
           label=" Account Balance"
           value={formatCurrency(form?.balance || 0, "GHS")}
         />
         <TextInput
           disabled
           mt={10}
           placeholder="Phone number"
           label="Phone number"
           value={form?.phoneNumber}
         />
         <TextInput
           type="date"
           mt={10}
           label="Accounting Date"
           value={accountingDate}
           onChange={(e: any) => setAccountingDate(e.target.value)}
         />

         <Button
          loading={isloadingTrans}
          disabled={form.customerName ? false : true}
           bg="#000"
           mt="md"
           fullWidth
           onClick={() => setProceedPayment(true)}
         >
          Proceed
         </Button>
       </Tabs>
       }
      </Modal>

      <div
        className="dailyTransactionsSection"
        style={{
          // marginInline: 50,
          borderRadius: 10,
          marginBottom: 20,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Text style={{ color: "#000", fontSize: 12 }}>
            Total Daily Transaction
          </Text>
          <Title c={"#000"} order={1} mt={5} style={{ fontSize: 40 }}>
            {" "}
            {formatCurrency(dailyTotal?.total_daily_transactions, "GHS")}{" "}
            <Badge
              mr={5}
              color="green"
              leftSection={<IconArrowDown size={12} />}
            >
              <span style={{ fontWeight: "bolder" }}>
                {formatCurrency(dailyTotal.total_deposits, "GHS")}
              </span>{" "}
              Deposit
            </Badge>
            <Badge leftSection={<IconArrowUpRight size={12} />} color="orange">
              {" "}
              <span style={{ fontWeight: "bolder" }}>
                {formatCurrency(dailyTotal.total_withdrawals, "GHS")}{" "}
              </span>{" "}
              Withdrawal
            </Badge>{" "}
          </Title>
          <Text fw={"bolder"} style={{ fontSize: 12 }} c="#008000" mt={5}>
            As at {new Date().toLocaleString()}
          </Text>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          {loading ? (
            <Loader style={{ cursor: "pointer" }} size={20} color="#000" />
          ) : (
            <IconRefresh
              style={{ cursor: "pointer" }}
              onClick={() => {
                readRecentTransactionsData();
                refreshDailyTotal();
                refreshDailyTransactions();
                refreshCustomers();
                refresh();
              }}
            />
          )}
          <Title className="removeOnMobile" ml={5} mr={20} order={6}>
            Refresh{" "}
          </Title>
          {isCollaborator &&  (
            <Button
              className="removeOnMobile"
              size="xs"
              onClick={open}
              leftSection={<IconPlus />}
              ml={10}
              variant="filled"
              bg="#000"
            >
              New Transaction
            </Button>
          )}
          {isAdmin &&  (
            <Button
              className="removeOnMobile"
              size="xs"
              onClick={open}
              leftSection={<IconPlus />}
              ml={10}
              variant="filled"
              bg="#000"
            >
              New Transaction
            </Button>
          )}

        </div>
      </div>

      <Card />

      <Paper className="recentTransactionsTable" mb={10} withBorder style={{borderColor: '#dedede',}} p={20}>
        {loading ? <Skeleton style={{ height: 350, lineHeight: '100px', margin: '10px 0' }}  active /> : (
        <>
            <Title mb={10} order={5}>
          Weekly Transaction Count Metrics
        </Title>
        <AreaChart
          yAxisLabel="Transaction Count"
          xAxisLabel="Transaction Date"
          withLegend
          h={350}
          data={weeklyMetricsData}
          dataKey="date"
          xAxisProps={{
            axisLine: { stroke: "black", strokeWidth: 1 },
          }}
          yAxisProps={{ domain: [0, 100], axisLine: { stroke: "black", strokeWidth: 1 } }}
          referenceLines={[
            { y: 50, label: 'Average Transaction Count', color: 'red.6' },
          ]}
          series={[
            { name: "deposits", label: "Deposit", color: "green.6" },
            { name: "withdrawals", label: "Withdrawal", color: "orange.6" },
          ]}
          curveType="linear"
        />
        </>
        )}
    
      </Paper>

      <Paper
        withBorder
        className="recentTransactionsTable"
        p={20}
        h={"100%"}
        style={{ borderRadius: 10, borderColor: '#dedede' }}
      >
        <Title order={4} mb={20}>
          Recent transactions
        </Title>

        <div
          style={{ overflowX: "auto", overflowY: "auto", maxHeight: "100%" }}
        >
          <Table
            striped
            stripedColor="#f2f2f2"
            highlightOnHover
            highlightOnHoverColor="#f4f3ef"
          >
            <Table.Thead style={{ backgroundColor: "#fafafa" }}>
              <Table.Tr>
                <Table.Th style={{ fontWeight: "bold" }}>TID</Table.Th>
                <Table.Th style={{ fontWeight: "bold" }}>Date</Table.Th>
                <Table.Th style={{ fontWeight: "bold" }}>Customer</Table.Th>
                <Table.Th style={{ fontWeight: "bold" }}>
                  Transaction Type
                </Table.Th>
                <Table.Th style={{ fontWeight: "bold" }}>
                  Account number
                </Table.Th>
                <Table.Th style={{ fontWeight: "bold" }}>Amount</Table.Th>
                <Table.Th style={{ fontWeight: "bold" }}>Agent</Table.Th>
                <Table.Th style={{ fontWeight: "bold" }}>Status</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
          </Table>
        </div>
      </Paper>
    </div>
  );
}
