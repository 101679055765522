import { ArrowRight } from "lucide-react"; // ArrowDown for scroll indicator
import { ROUTES } from "../../routes/const";
import { useInView } from "react-intersection-observer";
import { useUser } from "../../context/UserContext";
import AppImage from '../../assets/appImages/appImage.png'
const LandingHero = () => {
  const { ref } = useInView({
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
  });
  const { user } = useUser();

  return (
    <header
      className="flex justify-center items-center max-h-screen"
      id="landing-hero"
      ref={ref}
      style={{ height: "100dvh", color: "#fff" }}
    >
      <div className="overlay py-10 lg:py-0 flex flex-col justify-center items-center text-center">
        <div className="flex flex-col lg:flex-row mt-20 items-center content-center px-4">
          <div className="lg:w-8/12 mx-auto text-center mt-20">
            <h1 className="text-4xl font-extrabold md:text-5xl lg:text-6xl text-black mb-5 animate-fadeIn leading-tight sm:leading-none Hero">
              Eliminate{" "}
              <span className="text-yellow-500 font-extrabold">Paperwork</span>{" "}
               for Enhanced Susu & Micro-credit Accountability
            </h1>
            <p className="text-black text-xl mb-10 animate-slide Hero">
            Enhance transparency and ensure efficient accountability—no paperwork needed. Empower your field agents to seamlessly collect deposits on the go, enabling faster transactions and improved customer service.
            </p>
           
            
            <a
              href={ROUTES.login}
              className="mb-5 flex justify-center"
              style={{ alignItems: "center", marginTop: 50 }}
            >
              <button className="btn bg-yellow-500 text-black px-8 sm:px-10 py-3 rounded-full hover:bg-black hover:text-yellow-500 transition ease-out duration-300 flex items-center Hero">
                {user?.uid ? "Dashboard" : "Get Started"}
                <ArrowRight size={15} className="ml-2" />
              </button>
            </a>
          </div>
        </div>
        <img className="animate-fadeIn p-2 w-full sm:w-5/6 md:w-2/3 lg:w-1/2" src={AppImage} width={'50%'}   style={{ maxWidth: "100%", height: "auto"}}
 alt="appImage"/>
      </div>
    </header>
  );
};

export default LandingHero;
