import { Alert} from '@mantine/core'
import { IconInfoCircle } from '@tabler/icons-react'

interface infoProp{
  info: string
  show: boolean
}
export default function InfoBanner({info, show}: infoProp) {

  return (
    <>
    {show  &&  <Alert icon={<IconInfoCircle />} color="red" style={{borderRadius: 'none', padding: 10, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
    <small>{info}</small>
  </Alert>}
    </>

  
  )
}

