import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { auth } from '../firebase';
import { collection, query, where, orderBy, Timestamp, getDocs, limit, startAfter } from 'firebase/firestore';
import { db } from '../firebase';
import { set } from 'date-fns';

interface Transaction {
    id: string;
    transId: string;
    customer: string;
    amount: string;
    accountNumber: string;
    date: string;
    agentName: string;
    agentID: string;
    transactionsMethod: string;
    agentEmail: string;
    phone: string;
    createdAt: any;
    approved: boolean;
    transactionType: string;
    reversed: boolean;
    orgId?: string;
    userId?: string; 
}

interface TransactionDataContextProps {
    depositTransactions: Transaction[];
    withdrawalTransactions: Transaction[];
    loading: boolean
    loadMoreDeposits: () => Promise<Transaction[]>;
    loadMoreWithdrawals: () => Promise<Transaction[]>;
    downloadStatement: () => Promise<Transaction[]>
    filterByDate: (type: 'Deposit' | 'Withdrawal', startDate: string, endDate: string) => Promise<Transaction[]>
    refreshTransactions: (transactionType: "Deposit" | "Withdrawal") => Promise<Transaction[]>
    loadingStatementDownload: boolean
    loadingDateFilter: boolean
    loadingRefresh: boolean
}

const TransactionDataContext = createContext<TransactionDataContextProps | null>(null);

// const getCurrentMonthTimestamps = () => {
//     const now = new Date();
//     return {
//         startTimestamp: Timestamp.fromDate(new Date(now.getFullYear(), now.getMonth(), 1)),
//         endTimestamp: Timestamp.fromDate(new Date(now.getFullYear(), now.getMonth() + 1, 0)),
//     };
// };

export const TransactionDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [depositTransactions, setDepositTransactions] = useState<Transaction[]>([]);
    const [withdrawalTransactions, setWithdrawalTransactions] = useState<Transaction[]>([]);
    const [lastDepositDoc, setLastDepositDoc] = useState<any>(null);
    const [lastWithdrawalDoc, setLastWithdrawalDoc] = useState<any>(null);
    const [loading, setLoading] = useState(false); 
    const [loadingDownloadStatement, setLoadingDownloadStatement] = useState(false)
    const [loadingDateFilter, setLoadingDateFilter] = useState(false)
    const [loadingRefresh, setLoadingRefresh] = useState(false)
    const user = auth.currentUser;
    const defaultQueryLimit = 50

    const downloadCustomerStatement= async () => {
        if (!user) return [];
      
        try {
            setLoadingDownloadStatement(true)
          const idTokenResult = await user.getIdTokenResult();
          const claims = idTokenResult.claims;
      
          if (!(claims.admin || claims.role === 'Collaborator')) {
            return [];
          }
      
          const depositQuery = query(
            collection(db, 'transactions'),
            where('orgId', '==', claims.orgId),
            where('transactionType', '==', 'Deposit'),
            orderBy('createdAt', 'desc')
          );
      
          const withdrawalQuery = query(
            collection(db, 'transactions'),
            where('orgId', '==', claims.orgId),
            where('transactionType', '==', 'Withdrawal'),
            orderBy('createdAt', 'desc')
          );
      
          // Fetch deposits
          const depositSnapshot = await getDocs(depositQuery);
          const depositData = depositSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })) as Transaction[];
      
          // Fetch withdrawals
          const withdrawalSnapshot = await getDocs(withdrawalQuery);
          const withdrawalData = withdrawalSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })) as Transaction[];
      
          return [...depositData, ...withdrawalData];
        } catch (error) {
          console.error('Error downloading entire statement:', error);
          return []; 
        }finally{
            setLoadingDownloadStatement(false)
        }
      };


      const getTransactionByDateFilter = useCallback(
        async (type: 'Deposit' | 'Withdrawal', startDate: string, endDate: string) => {
          if (!user) return [];
      
          try {
            setLoadingDateFilter(true)
            const idTokenResult = await user.getIdTokenResult();
            const claims = idTokenResult.claims;
      
            if (!(claims.admin || claims.role === 'Collaborator')) return [];
      
            const startTimestamp = Timestamp.fromDate(new Date(startDate));
      
            // Set endDate to the last millisecond of the day (23:59:59.999)
            const endDateObj = new Date(endDate);
            endDateObj.setHours(23, 59, 59, 999);
            const endTimestamp = Timestamp.fromDate(endDateObj);
      
            const transactionQuery = query(
              collection(db, 'transactions'),
              where('orgId', '==', claims.orgId),
              where('transactionType', '==', type),
              where('createdAt', '>=', startTimestamp),
              where('createdAt', '<=', endTimestamp),
              orderBy('createdAt', 'desc')
            );
      
            const snapshot = await getDocs(transactionQuery);
            const filteredDateTransactions = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            })) as Transaction[];
            
            return filteredDateTransactions;
          } catch (error) {
            console.error('Error filtering transactions by date:', error);
            return [];
          }finally{
            setLoadingDateFilter(false)
          }
        },
        [user]
      );

      
      const refreshTransactions = useCallback(async (transactionType: "Deposit" | "Withdrawal") => {
        if (!user) return [];
    
        const idTokenResult = await user.getIdTokenResult();
        const claims = idTokenResult.claims;
    
        if (!(claims.admin || claims.role === "Collaborator")) return [];
    
        try {
          setLoadingRefresh(true)
    
            if (transactionType === "Deposit") {
              const depositQuery = query(
                collection(db, "transactions"),
                where("orgId", "==", claims.orgId),
                where("transactionType", "==", "Deposit"),
                orderBy("createdAt", "desc"),
                limit(defaultQueryLimit)
            );
                const depositSnapshot = await getDocs(depositQuery);
                const depositData = depositSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                })) as Transaction[];
                setLastDepositDoc(depositSnapshot.docs[depositSnapshot.docs.length - 1]);
                setDepositTransactions(depositData);
                 return depositData;
            } 
         
           else {
              const withdrawalQuery = query(
                collection(db, "transactions"),
                where("orgId", "==", claims.orgId),
                where("transactionType", "==", "Withdrawal"),
                orderBy("createdAt", "desc"),
                limit(defaultQueryLimit)
            );
                const withdrawalSnapshot = await getDocs(withdrawalQuery);
                const withdrawalData = withdrawalSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                })) as Transaction[];
                setLastWithdrawalDoc(withdrawalSnapshot.docs[withdrawalSnapshot.docs.length - 1]);
                setWithdrawalTransactions(withdrawalData);
                 return withdrawalData;
            } 

        } catch (error) {
            console.error(`Error fetching ${transactionType} transactions:`, error);
            return [];
        }
        finally{
          setLoadingRefresh(false)
        }
    }, [user]);
    
    
    
      

      const fetchTransactions = useCallback(async () => {
        if (!user) return;
    
        const idTokenResult = await user.getIdTokenResult();
        const claims = idTokenResult.claims;
    
        if (claims.admin || claims.role === "Collaborator") {
            try {
                const depositQuery = query(
                    collection(db, "transactions"),
                    where("orgId", "==", claims.orgId),
                    where("transactionType", "==", "Deposit"),
                    orderBy("createdAt", "desc"),
                    limit(defaultQueryLimit)
                );
    
                const withdrawalQuery = query(
                    collection(db, "transactions"),
                    where("orgId", "==", claims.orgId),
                    where("transactionType", "==", "Withdrawal"),
                    orderBy("createdAt", "desc"),
                    limit(defaultQueryLimit)
                );
    
                // Fetch deposit transactions
                const depositSnapshot = await getDocs(depositQuery);
                const depositData = depositSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                })) as Transaction[];
    
                setDepositTransactions(depositData);
                setLastDepositDoc(depositSnapshot.docs[depositSnapshot.docs.length - 1]);
    
                // Fetch withdrawal transactions
                const withdrawalSnapshot = await getDocs(withdrawalQuery);
                const withdrawalData = withdrawalSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                })) as Transaction[];
    
                setWithdrawalTransactions(withdrawalData);
                setLastWithdrawalDoc(withdrawalSnapshot.docs[withdrawalSnapshot.docs.length - 1]);
            } catch (error) {
                console.error("Error fetching transactions:", error);
            }
        }
    }, [user]);
    

    ///REMOVED ONSNAP
    // const fetchTransactions = useCallback(async () => {
    //     if (user) {
   
    //         const idTokenResult = await user.getIdTokenResult();
    //         const claims = idTokenResult.claims;
    //         // const { startTimestamp, endTimestamp } = getCurrentMonthTimestamps();

    //         if (claims.admin || claims.role === 'Collaborator') {
    //             const depositQuery = query(
    //                 collection(db, 'transactions'),
    //                 where('orgId', '==', claims.orgId),
    //                 where('transactionType', '==', 'Deposit'),
    //                 // where('createdAt', '>=', startTimestamp),
    //                 // where('createdAt', '<=', endTimestamp),
    //                 orderBy('createdAt', 'desc'),
    //                 limit(defaultQueryLimit)
    //             );

    //             const withdrawalQuery = query(
    //                 collection(db, 'transactions'),
    //                 where('orgId', '==', claims.orgId),
    //                 where('transactionType', '==', 'Withdrawal'),
    //                 // where('createdAt', '>=', startTimestamp),
    //                 // where('createdAt', '<=', endTimestamp),
    //                 orderBy('createdAt', 'desc'),
    //                 limit(defaultQueryLimit)
    //             );

    //             const unsubscribeDeposits = onSnapshot(depositQuery, (snapshot) => {
    //                 const depositData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })) as Transaction[];
    //                 setDepositTransactions(depositData);
    //                 setLastDepositDoc(snapshot.docs[snapshot.docs.length - 1]);
    //             });

    //             const unsubscribeWithdrawals = onSnapshot(withdrawalQuery, (snapshot) => {
    //                 const withdrawalData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })) as Transaction[];
    //                 setWithdrawalTransactions(withdrawalData);
    //                 setLastWithdrawalDoc(snapshot.docs[snapshot.docs.length - 1]);
    //             });

    //             return () => {
    //                 unsubscribeDeposits();
    //                 unsubscribeWithdrawals();
    //             };
    //         }
    //     }
    // }, [user]);

    useEffect(() => {
        fetchTransactions().catch(console.error);
    }, [fetchTransactions]);



    const loadMoreTransactions = async (
        type: 'Deposit' | 'Withdrawal',
        lastDoc: any,
        setter: React.Dispatch<React.SetStateAction<Transaction[]>>,
        setLastDoc: React.Dispatch<React.SetStateAction<any>>
    ) => {  
         if (!user || !lastDoc || loading) return [];
        setLoading(true); 
    
        try {
            const idTokenResult = await user.getIdTokenResult();
            const claims = idTokenResult.claims;
            const nextQuery = query(
                collection(db, 'transactions'),
                where('orgId', '==', claims.orgId),
                where('transactionType', '==', type),
                orderBy('createdAt', 'desc'),
                startAfter(lastDoc),
                limit(defaultQueryLimit)
            );
    
            const querySnapshot = await getDocs(nextQuery);
            const newTransactions = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })) as Transaction[];
    
            setter((prev) => [...prev, ...newTransactions]); // Update state
            setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
    
            return newTransactions;
        } catch (error) {
            console.error('Error loading more transactions:', error);
            return [];
        } finally {
            setLoading(false);
        }
    };

    


    return (
        <TransactionDataContext.Provider
            value={{
                depositTransactions,
                withdrawalTransactions,
                loadMoreDeposits: () => loadMoreTransactions('Deposit', lastDepositDoc, setDepositTransactions, setLastDepositDoc),
                loadMoreWithdrawals: () => loadMoreTransactions('Withdrawal', lastWithdrawalDoc, setWithdrawalTransactions, setLastWithdrawalDoc),
                downloadStatement: ()=> downloadCustomerStatement(),
                filterByDate: (type, startDate, endDate) => getTransactionByDateFilter(type, startDate, endDate),
                refreshTransactions: (transactionType) => refreshTransactions(transactionType),
                loading: loading,
                loadingStatementDownload: loadingDownloadStatement,
                loadingDateFilter: loadingDateFilter,
                loadingRefresh: loadingRefresh
            }}
        >
            {children}
        </TransactionDataContext.Provider>
    );
};

export const useTransactionDataContext = () => {
    const context = useContext(TransactionDataContext);
    if (!context) {
        throw new Error('useTransactionDataContext must be used within a TransactionDataProvider');
    }
    return context;
};
