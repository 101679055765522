import FAQListItem from "./FAQListItem";

const FAQ = () => {
  const questions = [
    {
      question: "What is Digicollect?",
      answer:
        "DigiCollect is a platform to streamline microfinance, micro-credit, Deposit-taking(Susu) business activities for enhanced transparency and accountability. It is designed to help you digitize your savings and loans transactions for streamlined processes, enhanced transparency, and efficient accountability—no paperwork needed.",
    },
    {
      question: "How do I get started?",
      answer: `You can get started by creating an account by clicking on the sign up button. You will need a valid business email address to sign up. After you are done with the sign up process, our team will review your application and get back to you.`,
    },
    {
      question: "How long does it take to verify my account?",
      answer:
        "It takes less than 24 hours to verify your account. You will receive an email notification once your account has been verified.",
    },
  ];

  return (
    <>
      <section className="bg-white mt-[10px] min-h-screen">
        <div className="container mx-auto px-3 py-10">
          <section>
            <h2 className="text-4xl font-bold mb-10 Hero text-black">
              Frequently Asked Questions(FAQ).
            </h2>

            <p className="text-gray-500 mb-10 Hero text-lg font-medium">
              These are some frequently asked questions. If you need more
              information don't hesitate to reach out to us.
            </p>

            <ul>
              {questions.map((question, index) => (
                <FAQListItem
                  key={index}
                  question={question.question}
                  answer={question.answer}
                />
              ))}
            </ul>
          </section>
        </div>
      </section>
    </>
  );
};

export default FAQ;
