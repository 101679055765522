import LandingLayout from "../../layouts/landing";
import LandingNav from "../../components/landingPage/LandingNav";

const landingAbout = () => {
  return (
    <LandingLayout>
      <LandingNav inView={true} />
      <main className="mt-[72px] bg-white">
        <div className="container mx-auto py-5 lg:py-10 px-3">
          <header className="mb-20">
            <h1 className="text-4xl font-bold mb-5 mt-20 Hero text-black">
              About Us.
            </h1>
            <p className="text-lg text-gray-500">
              <span className="text-yellow-500 Hero">DigiCollect</span>{" "}
              {/* <Link to="/register" className="text-green-500 hover:underline">
                Texa, LLC
              </Link>{" "} */}
              <em className="not-italic Hero">
                is your ultimate micro-finance management platform,
                revolutionizing how you manage daily business activities.
                DigiCollect tackles challenges in the micro-finance sector,
                making your operations faster, easier, and stress-free. Enjoy
                seamless access around the clock, empowering you to handle your
                business anytime, anywhere.
              </em>
            </p>
            <p className="text-lg text-gray-500 mt-5">
              <em className="not-italic Hero">
                With DigiCollect, you gain seamless access to a range of
                features that ensure comprehensive financial management around
                the clock. Whether you’re handling savings collection, managing
                savings, or tracking loans, DigiCollect empowers you to operate
                your business anytime and anywhere. Embrace the future of
                micro-finance with confidence, and let DigiCollect simplify your
                financial management.
              </em>
            </p>

            <div className="mt-20">
              <p className="Hero font-extrabold text-black">
                Important Notice:
              </p>
              <em className="mt-5 Hero text-gray-500 text-lg">
                This app does not facilitate actual payments. It is designed for
                tracking and managing your transaction activities.
              </em>
            </div>
          </header>
        </div>
      </main>
    </LandingLayout>
  );
};

export default landingAbout;
