import React, { useState } from 'react'
import { Badge, Button, Paper, Text, TextInput, Title } from '@mantine/core';
import { formatCurrency } from '../../utils/currencyFormat';
import { IconFileExcel, IconFilter, IconRotate } from '@tabler/icons-react';
import { handleExport } from '../../services/core-api';
import { useLoanDataContext } from '../../context/LoanContext';
import { formatDate } from 'date-fns';
import { DatePicker } from 'antd';
import TransactionsTable from '../../components/dashboard/TransactionsTable';


export default function LoanTransactions() {
  const [isloading, setIsloading]= useState(false)
  const {loanTransactions, setLoanTransactions} = useLoanDataContext()
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [transactions, setTransactions] = useState(loanTransactions)

  const filterData = () => {
    let filtered = transactions;

    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      end.setHours(23, 59, 59, 999);       
      filtered = filtered.filter(trans => {
        const transactionDate = new Date(trans.createdAt.seconds * 1000);
        return transactionDate >= start && transactionDate <= end;
      });
    }

    setTransactions(filtered);
  };
  
    
      const downloadData = ()=> {
        setIsloading(true)
        handleExport(loanTransactions? loanTransactions : []).then(()=> {
        setIsloading(false)
        }).catch((err)=> {
          console.log(err)
        setIsloading(false)
        })
      }

      const totalLoanPaid =  transactions.reduce((total, loan) => total + loan?.amountPaid, 0);

  return (
    <>
        <Paper
      withBorder
      style={{borderColor: '#dedede'}}
      p={20}
      >
        <div  style={{
          display: "flex",
          justifyContent: "start",
          gap: 20,
          alignItems: "center",
          flexWrap: 'wrap',
        }}>
           
    
         <div>
         <Badge variant='light' color='black' mb={5} style={{ fontWeight: 'bold'}}> Total Paid Loan</Badge>
         <Title mb={10} order={3}> {formatCurrency(totalLoanPaid, 'GHS')}</Title>
         </div>

         <div>
         <Badge variant='light' color='black' mb={5} style={{ fontWeight: 'bold'}}> Total Loans</Badge>
         <Title mb={10} order={3}> {transactions.length}</Title>
         </div>


        </div>
     

     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      </div>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20}}>
    <div style={{ display: 'flex', width: '70%', flexWrap: 'wrap', alignItems:'center'}}>
    <Button loading={isloading}  variant="outline" disabled={isloading} mr={10} color="#000" onClick={downloadData}> 
        <IconFileExcel /> &nbsp; Export to XLSx
        </Button>
        

        <DatePicker
         style={{ height: 35}}
         name='State date'
          width='20%'
          type='date'
          value={startDate}
          onChange={setStartDate}
          placeholder='Start Date'
          // mr={10}
        />
          <Text fw={'bold'}  m={5}>To</Text>

        <DatePicker
         style={{ height: 35}}
         name='End date'
          width='20%'
          type='date'
          value={endDate}
          onChange={setEndDate}
          placeholder='End Date'
          // mr={10}
        />

<Button leftSection={<IconFilter size={20}/>} variant='filled' color='#000' onClick={filterData} style={{ marginInline: 10 }}>
          Filter Date
        </Button>
        </div>

        <Button leftSection={<IconRotate size={20}/>} variant='filled' color='#000' onClick={()=> {
          setStartDate('')
          setEndDate('')
          setTransactions(loanTransactions)
        }} style={{ marginInline: 10 }}>
          Refresh
        </Button>
      </div>

      <div className='tableOnMobile'>
    <TransactionsTable showLoadMoreButton={false} showPageTotalAmount={false} data={transactions} dataType='loan'/>
    </div>
    </Paper>
    </>
  )
}


