import { onAuthStateChanged, sendEmailVerification, signOut } from "firebase/auth";
import { ROUTES } from "../../routes/const";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, Loader, Text, Title } from "@mantine/core";
import Lottie from "lottie-react";
import EmailLogo from '../../assets/verifyemail.json'
import notify from "../../utils/notify";

export default function VerifyEmail() {
    const navigate = useNavigate()
    const [loading, setLoading]= useState(false)

    const handleLogout = async () => {
        try {
          await signOut(auth).then(()=> {
          navigate(ROUTES.login);
          })
        } catch (error) {
          console.error("Logout error:", error);
        }
      };

      const resendVerification = async () => {
        setLoading(true)
        const user = auth.currentUser;
        if (user) {
          try {
            await sendEmailVerification(user)
            notify('A new verification email has been sent. Please check your inbox.');
        setLoading(false)
          } catch (error: any) {
            console.error('Error resending verification email:', error);
            notify('Error resending verification email: ' + error.message);
        setLoading(false)
          }
        } else {
          alert('No user is signed in.');
        setLoading(false)
        }
      };

      useEffect(()=> {
        // // Restore user session from Firebase
        const unsubscribe = onAuthStateChanged(auth, (user) => {
         if (user?.emailVerified) {
            navigate(ROUTES.home)
         } else {
          navigate(ROUTES.verifyEmail)
         }
       });
    
       return () => unsubscribe();
     },[])

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100vh', textAlign: 'center'}}>
    <Lottie animationData={EmailLogo} loop={true} style={{ height: 200}} />
    <Title>
    Verify Your Account
    <br/>
    </Title>
    <Text mt={10}> Verification link has been sent to your email. please check and click on the link to verify your account</Text>
     <div style={{ display: 'flex', marginTop: 40}}>

     <a href={ROUTES.login} onClick={handleLogout}>
     <Button mr={20} color="#000" variant="outline" >Logout</Button>
     </a>

        <Button disabled={loading} onClick={resendVerification} color="#000" variant="filled" >{loading ? <Loader size={18} color="#000" /> : 'Resend verification'}</Button>
     </div>
  
    </div>
  )
}