import { collection, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import { auth, db } from "../firebase";

export interface reverseProps {
    transId: string;
    customer: string;
    accountNumber: string;
    phone: string;
    transactionType: string;
}

export const reverseTransaction = async ({ transId, customer, accountNumber, transactionType }: reverseProps) => {
    const user = auth.currentUser;
    if(user){
        try {
            const idTokenResult = await user.getIdTokenResult();
            const claims = idTokenResult.claims;
                
            const transactionQ = query(
                collection(db, 'transactions'),
                where('customer', '==', customer),
                where('transId', '==', transId),
                where('accountNumber', '==', accountNumber),
                where("transactionType", '==', transactionType)
            );
    
            const querySnapshot = await getDocs(transactionQ);
    
            // If no transaction is found, exit
            if (querySnapshot.empty) {
                console.log("Transaction not found");
                return;
            }
    
            const transactionDoc = querySnapshot.docs[0];
    
            const updatedData = {
                ...transactionDoc.data(),
                reversed: true,
                reversedAt: new Date(),  
                reversedBy: claims.agentId
            };
    
            // Update the document in Firestore
            await updateDoc(doc(db, 'transactions', transactionDoc.id), updatedData);
            return('Transaction reversed successfully');
    
        } catch (error) {
            return(`Error reversing transaction: ${error}`);
        } finally {
            // setLoadingData(false); // If you have any loading state, you can handle it here
        }

    }
 
};