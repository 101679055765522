import React, {  useState } from "react";
import { db, auth } from "../../firebase";
import {
  Badge,
  Select,
  Table,
  TextInput,
  Title,
  Divider,
  Paper,
  Avatar,
  Pagination,
  Menu,
  Group,
  Text,
  Alert,
} from "@mantine/core";
import {
  handleExport,
  loanPayment,
  loanRequest,
  LoanRequestProp,
} from "../../services/core-api";
import { getDomainFromEmail } from "../../utils/getUserDomain";
import { useDisclosure } from "@mantine/hooks";
import { Modal, Button } from "@mantine/core";
import InputText from "../../components/InputText";
import { formatCurrency } from "../../utils/currencyFormat";
import {
  IconFileExcel,
  IconInfoSmall,
  IconPlus,
  IconCash,
  IconEdit,
  IconList,
} from "@tabler/icons-react";
import { formatDate } from "../../utils/formatDate";
import notify from "../../utils/notify";
import { useLoanDataContext } from "../../context/LoanContext";
import { useCustomerDataContext } from "../../context/customersContext";
import { customerProp } from "./customers";
import { useUser } from "../../context/UserContext";
import { nanoid } from "nanoid";

export default function Loans() {
  const [search, setSearch] = useState("");
  const [isloading, setIsloading] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const { user } = useUser();
  const {
    loans,
    totalPaid,
    totalLoanAmount,
    totalOutStandingLoanAmount,
    setLoans,
  } = useLoanDataContext();
  const { customers } = useCustomerDataContext();

  const [form, setForm] = useState({
    customer: "",
    phone: "",
    address: "",
    nationalId: "",
    accountNumber: "",
    accountBalance: 0,
    loanAmount: 0,
    interestRate: "",
  });

  const initialFormState = {
    customer: "",
    phone: "",
    address: "",
    nationalId: "",
    accountNumber: "",
    accountBalance: 0,
    loanAmount: 0,
    interestRate: "",
  };
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page

  // Calculate paginated data
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedLoans = loans?.slice(startIndex, endIndex);
  const [isRequestingLoan, setIsRequestingLoan] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);

  const toggleRow = (index: any) => {
    setExpandedRow(expandedRow === index ? null : index);
  };


  const rows = paginatedLoans?.map((loan, index) => (
    <React.Fragment key={index}>
      <Table.Tr onClick={() => toggleRow(index)} style={{ cursor: "pointer" }}>
        <Table.Td
          onClick={() => toggleRow(index)}
          style={{
            whiteSpace: "nowrap",
            cursor: "pointer",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {" "}
          <IconList size={16} />
        </Table.Td>
        <Table.Td
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {formatDate(loan?.createdAt)}
        </Table.Td>

        <Table.Td
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontWeight: 'bold'
          }}
        >
          {loan.customer}
        </Table.Td>
        <Table.Td
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontWeight: "bolder",
          }}
        >
          {loan.accountNumber}
        </Table.Td>
        <Table.Td
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {formatCurrency(loan.accountBalance, "GHS")}
        </Table.Td>

        <Table.Td
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontWeight: "bold",
          }}
        >
          {formatCurrency(loan.loanAmount, "GHS")}
        </Table.Td>
        <Table.Td
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontWeight: 'bold',
            color: 'red'
          }}
        >
          {formatCurrency(loan.outstandingBalance, "GHS")}
        </Table.Td>
        <Table.Td
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >{`${loan.tenure} Months`}</Table.Td>
        <Table.Td
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >{`${formatCurrency(
          parseFloat(loan.monthlyInstallment),
          "GHS"
        )}`}</Table.Td>
        
        <Table.Td
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >{`${loan.interestRate}%`}</Table.Td>


        <Table.Td
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Badge
            size="sm"
            color={loan.outstandingBalance > 0 ? "red" : "green"}
          >
            {loan.outstandingBalance > 0 ? "Not complete" : "Paid"}
          </Badge>
        </Table.Td>

      </Table.Tr>

      {/* Collapsible details row */}
      {expandedRow === index && (
        <Table.Tr>
          <Table.Td colSpan={14}>
            <div style={{ padding: "30px", backgroundColor: "#f9f9f9" }}>
              <Title order={5} mb={10}>
                {" "}
                Details:
                <p style={{ fontWeight: "bold", marginTop: 10 }}>
                  Total Loan Payment:{" "}
                  {formatCurrency(parseFloat(loan?.totalPayment), "GHS")}{" "}
                </p>
                <p>
                  Interest Amount:{" "}
                  {formatCurrency(loan.interestRateAmount, "GHS")}
                </p>
                <p>Agent Name: {loan?.agentName}</p>
                <p>Agent ID: {loan.agentId}</p>
                <p> Phone: {loan.phone}</p>
              </Title>
            </div>

          </Table.Td>
        </Table.Tr>
      )}
    </React.Fragment>
  ));

  function openModal() {
    open();
  }

  const filterData = () => {
    const filtered = loans?.filter((client) =>
      client.customer.includes(search.charAt(0).toUpperCase().trim())
    );
    setLoans(filtered);
  };

  const downloadData = () => {
    if (loans.length <= 0) {
      notify("No data to export", "success");
      return;
    }
    setIsloading(true);
    handleExport(loans)
      .then(() => {
        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
      });
  };

  const [loanDetails, setLoanDetails] = useState({
    customer: "",
    loanAmount: "",
    tenure: "",
    interestRate: "",
  });


  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLoanDetails({ ...loanDetails, [name]: value });
  };


  const saveLoan = async () => {
    const { loanAmount, tenure, interestRate } = loanDetails;
    const principal = parseFloat(loanAmount);
    const rate = parseFloat(interestRate) / 100;
    const months = parseInt(tenure);
  
    try {
      setIsRequestingLoan(true);
      if (principal && rate && months) {
        const totalInterest = principal * rate * months;
        const monthlyInterest = totalInterest / months;
        
        // Calculate monthly installment with evenly spread interest
        const monthlyPrincipal = principal / months;
        const emi = monthlyPrincipal + monthlyInterest;
        
        const totalPayment = emi * months; // Recalculate total payment
  
        const loanRequestPayload: LoanRequestProp = {
          customer: form?.customer || "",
          phone: form?.phone || "",
          accountNumber: form?.accountNumber || "",
          accountBalance: form?.accountBalance || 0,
          loanAmount: principal,
          outstandingBalance: parseFloat(totalPayment.toFixed(2)),
          tenure: parseFloat(tenure),
          interestRate: interestRate.toString(),
          interestRateAmount: parseFloat(totalInterest.toFixed(2)),
          monthlyInstallment: parseFloat(emi.toFixed(2)),
          totalPayment: parseFloat(totalPayment.toFixed(2)),
          agentName: user?.displayName || "",
        };
  
        const response = await loanRequest(loanRequestPayload);
        notify(response.message, "success");
        setOpenedLoanForm(false);
        setForm(initialFormState);
      }
    } catch (err: any) {
      notify(err.message, "error");
    } finally {
      setIsRequestingLoan(false);
    }
  };
  


  const [paymentAmount, setPaymentAmount] = useState("");
  const transId = nanoid(8);
  const [loanPaymentForm, setLoanPaymentForm] = useState({
    transId,
    customer: "",
    accountNumber: "",
    accountBalance: 0,
    loanAmount: 0,
    monthlyInstallment: 0,
    amountPaid: 0,
    agentName: ""
  });
  const [processingPayment, setProcessingPayment] = useState<boolean>(false);

  const handleLoanPayment = async () => {
    if (!paymentAmount || isNaN(parseFloat(paymentAmount))) {
      return;
    }
    const paymentData = {
      transId,
      customer: loanPaymentForm.customer,
      accountNumber: loanPaymentForm.accountNumber,
      accountBalance: loanPaymentForm.accountBalance,
      loanAmount: loanPaymentForm.loanAmount,
      monthlyInstallment: loanPaymentForm.monthlyInstallment,
      amountPaid: parseFloat(paymentAmount),
      agentName: user?.displayName || ''
    };

    try {
      setProcessingPayment(true);
      const paymentResult = await loanPayment(paymentData);
      notify(paymentResult.message, "success");
    } catch (error: any) {
      notify(error.message, "error");
    } finally {
      setProcessingPayment(false);
      setPaymentModalOpened(false);
    }
  };

  const [openedLoanForm, setOpenedLoanForm] = useState(false);
  const [paymentModalOpened, setPaymentModalOpened] = useState(false);
  return (
    <div>
      <Modal
        opened={openedLoanForm}
        centered
        onClose={() => setOpenedLoanForm(false)}
        title={<div style={{ fontWeight: "bold" }}>
          Apply loan
          </div>}
      >
        <Paper p="md">
          <Divider my="sm" />
          <Select
            leftSection={
              <Avatar
                key={form?.accountNumber}
                name={form?.customer}
                color="initials"
                size={30}
              />
            }
            searchable
            label="Select Customer"
            placeholder="Choose customer"
            data={customers?.map((customer: customerProp) => ({
              value: customer.accountNumber,
              label: customer.fullName,
            }))}
            name="customer"
            onChange={(value) => {
              const selectedCustomer = customers.find(
                (customer: customerProp) => customer?.accountNumber === value
              );
              if (selectedCustomer) {
                setForm({
                  ...form,
                  customer: selectedCustomer.fullName,
                  phone: selectedCustomer.phone,
                  accountNumber: selectedCustomer.accountNumber,
                  accountBalance: parseFloat(selectedCustomer.accountBalance),
                });
              }
            }}
            renderOption={(value) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  mr={5}
                  key={value.option.value}
                  name={value.option.label}
                  color="initials"
                />
                <p>{value.option.label}</p>
              </div>
            )}
          />

          <TextInput
            label="Loan Amount"
            placeholder="Enter loan amount"
            name="loanAmount"
            value={loanDetails.loanAmount}
            onChange={handleInputChange}
            mt="sm"
          />
          <TextInput
            label="Tenure (months)"
            placeholder="Enter tenure"
            name="tenure"
            value={loanDetails.tenure}
            onChange={handleInputChange}
            mt="sm"
          />
          <TextInput
            label="Interest Rate (%)"
            placeholder="Enter annual interest rate"
            name="interestRate"
            value={loanDetails.interestRate}
            onChange={handleInputChange}
            mt="sm"
          />
          <Button
            disabled={
              isNaN(parseFloat(loanDetails.tenure))||
              isNaN(parseFloat(loanDetails.interestRate)) ||
              isNaN(parseFloat(loanDetails.loanAmount))
            }
            loading={isRequestingLoan}
            color="#000"
            fullWidth
            mt="md"
            onClick={() => saveLoan()}
          >
           Proceed
          </Button>
          <Divider my="sm" />
       
       <Alert color={'yellow'}>
       <Group>
        <Text fw={'bold'}>Total Interest:</Text>
         {loanDetails && formatCurrency(parseFloat(loanDetails.loanAmount) *
        parseFloat(loanDetails.interestRate) / 100 *
        parseInt(loanDetails.tenure) || 0, "GHS")}
       </Group>
   
     <Group>
      <Text fw={'bold'}> Monthly Installment:</Text>
         {loanDetails && formatCurrency(
           parseFloat(loanDetails.loanAmount) / parseInt(loanDetails.tenure) +
          parseFloat(loanDetails.loanAmount) *
        parseFloat(loanDetails.interestRate) / 100 *
        parseInt(loanDetails.tenure) / parseInt(loanDetails.tenure) || 0, "GHS")}
     </Group>
       </Alert>
       
        </Paper>
      </Modal>

      <Modal
        centered
        opened={paymentModalOpened}
        onClose={() => setPaymentModalOpened(false)}
        title={<div style={{ fontWeight: "bold" }}>Loan Payment</div>}
      >
        <Paper p="md">
          <Select
            leftSection={
              <Avatar
                key={form?.accountNumber}
                name={form?.customer}
                color="initials"
                size={30}
              />
            }
            searchable
            label="Select Customer"
            placeholder="Choose customer"
            data={loans
              ?.map((data: any) => ({
                value: data.accountNumber,
                label: data.customer,
              }))
              .reduce((acc: any[], current: any) => {
                const existing = acc.find(
                  (item) => item.value === current.value
                );
                if (existing) {
                  // If a duplicate is found, append " (Duplicate)" to the label
                  existing.label = existing.label + "";
                  existing.isDuplicate = true;
                } else {
                  acc.push(current);
                }
                return acc;
              }, [])}
            name="customer"
            onChange={(value) => {
              const selectedCustomer = loans.find(
                (data: any) => data?.accountNumber === value
              );
              if (selectedCustomer) {
                setLoanPaymentForm({
                  ...loanPaymentForm,
                  customer: selectedCustomer.customer,
                  loanAmount: selectedCustomer.loanAmount,
                  monthlyInstallment: parseFloat(
                    selectedCustomer.monthlyInstallment
                  ),
                  accountNumber: selectedCustomer.accountNumber,
                  accountBalance: selectedCustomer.accountBalance,
                  amountPaid: parseFloat(paymentAmount),
                });
              }
            }}
            renderOption={(value: any) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  mr={5}
                  key={value.option.value}
                  name={value.option.label}
                  color="initials"
                />
                <p>{value.option.label}</p>
              </div>
            )}
          />

          <TextInput
            label="Payment Amount"
            placeholder="Enter payment amount"
            value={paymentAmount}
            onChange={(e) => {
              setPaymentAmount(e.target.value);
              setLoanPaymentForm({
                ...loanPaymentForm,
                amountPaid: parseFloat(paymentAmount),
              });
            }}
            mt="sm"
          />
          <Button
            loading={processingPayment}
            color="#000"
            fullWidth
            mt="md"
            onClick={handleLoanPayment}
            disabled={
              !paymentAmount ||
              isNaN(parseFloat(paymentAmount)) ||
              paymentAmount.includes(",")
            }
          >
            Make Payment
          </Button>
        </Paper>
      </Modal>

  
      <Paper p={20} withBorder style={{borderColor: '#dedede'}}>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Title order={4}> Loans </Title>
        </div>

     
          <div style={{ display: "flex",  marginRight: '20px', gap: 20, justifyContent: 'end', alignItems: "center" }}>
           
           <div>
           <Badge variant='light' color='black' mb={5} style={{ fontWeight: 'bold'}}>
              Total Amount
            </Badge>
            <p style={{ fontWeight: "bold", color: "green" }}>
                {formatCurrency(totalLoanAmount, "GHS")}
              </p>
           </div>
     
         <div>
         <Badge variant='light' color='black' mb={5} style={{ fontWeight: 'bold'}}>
            Total Outstanding
          </Badge>
          <p style={{ fontWeight: "bold", color: "red" }}>
              {" "}
              {formatCurrency(totalOutStandingLoanAmount, "GHS")}
            </p>
         </div>
         
          
          <div>
          <Badge variant='light' color='black' mb={5} style={{ fontWeight: 'bold'}}>
            Total Paid
          </Badge>
          <p style={{ fontWeight: "bold", color: "green" }}>
              {" "}
              {formatCurrency(totalPaid, "GHS")}
            </p>
          </div>
         

        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 20,
            width: "100%",
          }}
        >
          <div>
            <Button
              disabled={isloading}
              variant="outline"
              mr={10}
              color="#000"
              onClick={downloadData}
            >
              <IconFileExcel /> &nbsp; Export Data
            </Button>
          </div>

          <div style={{ display: "flex" }}>
            <Button
              mr={5}
              leftSection={<IconCash size={20} />}
              color="#000"
              onClick={() => setPaymentModalOpened(true)}
            >
              Loan Payment
            </Button>

            <Button
              variant="outline"
              // disabled
              leftSection={<IconPlus size={20} />}
              color="#000"
              onClick={() => {
                setOpenedLoanForm(true)
              }}
            >
              New Loan
            </Button>
          </div>
        </div>

        <div
          style={{ overflowX: "auto", overflowY: "auto", maxHeight: "100%" }}
        >
          <Table
            highlightOnHover
            stripedColor="#f2f2f2"
            highlightOnHoverColor="#f4f3ef"
            stickyHeader
            stickyHeaderOffset={10}
            striped
            mt={20}
          >
            <Table.Thead style={{ backgroundColor: "#fafafa" }}>
              <Table.Tr>
                <Table.Th>
                  <IconInfoSmall />{" "}
                </Table.Th>
                <Table.Th style={{ fontWeight: "bold" }}>Date</Table.Th>
                <Table.Th style={{ fontWeight: "bold" }}> Name</Table.Th>
                <Table.Th style={{ fontWeight: "bold" }}>Acc Number</Table.Th>
                <Table.Th style={{ fontWeight: "bold" }}>
                  Account Balance
                </Table.Th>
                <Table.Th style={{ fontWeight: "bold" }}>Loan Amount</Table.Th>
                <Table.Th style={{ fontWeight: "bold" }}>
                  Outstanding Balance
                </Table.Th>
                <Table.Th style={{ fontWeight: "bold" }}>Tenure</Table.Th>
                <Table.Th style={{ fontWeight: "bold" }}>
                  Monthly Installment
                </Table.Th>
                <Table.Th style={{ fontWeight: "bold" }}>
                  Interest Rate
                </Table.Th>
                <Table.Th style={{ fontWeight: "bold" }}>Status</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
          </Table>
          <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
          <Pagination
          color="#000"
        total={Math.ceil(loans.length / itemsPerPage)}
        value={currentPage}
        onChange={setCurrentPage}
        // position="center"
        mt="md"
      />
          </div>
        
        
        </div>
      </Paper>
    </div>
  );
}
