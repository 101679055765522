import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const notify = (message: string, type: 'info' | 'success' | 'error' | 'warning' = 'info') => {
    switch (type) {
        case 'success':
            toast.success(message, {
                style: {
                    backgroundColor: "#abf2ab",
                    color: "#000",
                    fontSize: 14
                },
                onOpen: () => {
                    const audio = new Audio('/notify2.mp3');
                    audio.play();
                },
            });
            break;
        case 'error':
            toast.error(message, {
                style: {
                    backgroundColor: "#ffcccc", 
                    color: "#000",
                    fontSize: 14
                },
                onOpen: () => {
                    const audio = new Audio('/notify2.mp3');
                    audio.play();
                },
            });
            break;
        case 'warning':
            toast.warn(message, {
                style: {
                    backgroundColor: "yellow",
                    color: "#fff",
                    fontSize: 14
                },
                onOpen: () => {
                    const audio = new Audio('/notify2.mp3');
                    audio.play();
                },
            });
            break;
        default:
            toast.info(message, {
                style: {
                    backgroundColor: "#000",
                    color: "#fff",
                    fontSize: 14
                },
                onOpen: () => {
                      const audio = new Audio('/notify2.mp3');
                      audio.play();
                  },
            });
            break;
    }
};

export default notify;
