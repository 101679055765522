import React, { useEffect, useState } from 'react';
import { Button, Badge, Title, Paper, Text, Group } from '@mantine/core';
import { IconCalendarCancel, IconFileExcel, IconFilter, IconRotate } from '@tabler/icons-react';
import { formatCurrency } from '../../utils/currencyFormat';
import { handleExport } from '../../services/core-api';
import { useTransactionDataContext } from '../../context/TransactionsContext';
import { DatePicker } from 'antd';
import notify from '../../utils/notify';
import TransactionsTable from '../../components/dashboard/TransactionsTable';

export default function Deposit() {
  // const [transactions, setTransactions] = useState<transactionsType[]>([]);
  // const [originalTransactions, setOriginalTransactions] = useState<transactionsType[]>([]);
  const [search, setSearch] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isloading, setIsloading] = useState(false);
  const [customerName, setCustomerName]= useState('')
  const {depositTransactions, loadMoreDeposits, filterByDate, loadingDateFilter, refreshTransactions, loadingRefresh } = useTransactionDataContext()
  const [transactions, setTransactions] = useState<Transaction[]>(depositTransactions)

interface Transaction {
  id: string;
  customer: string;
  amount: string;
  accountNumber: string;
  date: string;
  agentName: string;
  agentID: string;
  transactionsMethod: string;
  agentEmail: string;
  phone: string;
  createdAt: any;
  approved: boolean;
  transactionType: string;
  reversed: boolean,
  orgId?: string; // Optional, if you don't always have it
  userId?: string; 
}

const filterData = async () => {
  let filtered = transactions;

  if (search) {
    filtered = filtered.filter(trans =>
      trans.accountNumber.toLowerCase().includes(search.toLowerCase())
    );
  }

  if (customerName) {
    filtered = filtered.filter(trans =>
      trans.customer.toLowerCase().includes(customerName.toLowerCase())
    );
  }

  if (!startDate && !endDate) {
    return setTransactions(depositTransactions);
  }

  if (startDate && endDate) {
    try {
      const data: Transaction[] = await filterByDate('Deposit', startDate, endDate);
      setTransactions(data);
      return; 
    } catch (error) {
      console.error('Error filtering transactions:', error);
    }
  }

  setTransactions(filtered);
};


  

  const downloadData = () => {
    if(transactions.length <= 0) {
      notify("No data to export", 'error')
      return
    }
    setIsloading(true);
    const cleanedTransactions = transactions.map(({ orgId, userId, ...cleanedTransaction }) => cleanedTransaction);
    handleExport(cleanedTransactions ? cleanedTransactions : [])
      .then(() => {
        setIsloading(false);
      })
      .catch(err => {
        console.log(err);
        setIsloading(false);
      });
  };

     // Calculate the total amount of Transactions
     const totalTransactionsAmount = transactions
     .filter((transaction) => !transaction.reversed)
     .reduce((sum: number, trans: Transaction) => sum + parseFloat(trans.amount), 0);

     const totalNumberReversedTransactions =  transactions.filter((transaction) => transaction.reversed).length;

  

     const handleLoadMoreData = async () => {
       const moreData = await loadMoreDeposits();
       if(moreData.length !== 0){
        setTransactions((prev)=> [...prev, ...moreData])
       }else{
       notify('You’ve reached the end! No more transactions available', 'info')
       }
  };
  const handleRefresh = async()=> {
    const data =  await refreshTransactions('Deposit')
    setTransactions(data)
  }

    const handleFilterReset = ()=> {
      setStartDate('')
      setEndDate('')
      setTransactions(depositTransactions)
    }

  return (
    <>
     <Paper
      withBorder
      p={20}
      style={{borderColor: '#dedede'}}
      >
        <div  style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: 20,
          flexWrap: 'wrap',
        }}>

            
       <Group>
       <div>
         <Badge variant='light' color='black' mb={5} style={{ fontWeight: 'bold'}}> Total Balance</Badge>
         <Title mb={10} order={3}> {formatCurrency(totalTransactionsAmount, 'GHS')}</Title>
         </div>

         <div>
         <Badge variant='light' color='black' mb={5} style={{ fontWeight: 'bold'}}> Total Count</Badge>
         <Title mb={10} order={2}> {transactions.length}</Title>
         </div>
         <div>
         <Badge variant='light' color='black' mb={5} style={{ fontWeight: 'bold'}}> Total Reversed</Badge>
         <Title mb={10} order={2}> {totalNumberReversedTransactions}</Title>
         </div>
       </Group>

        </div>


       <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 20}}>
       <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
       <Button leftSection={ <IconFileExcel /> } loaderProps={{type: 'dots', size: 18}} className='removeOnMobile' variant='outline' loading={isloading} disabled={isloading} mr={10} color='#000' onClick={downloadData}>
       Export to XLSx
        </Button>
  
       <DatePicker
         style={{ height: 35}}
         name='State date'
          width='20%'
          type='date'
          value={startDate}
          onChange={setStartDate}
          placeholder='Start Date'
          // mr={10}
        />
        <Text fw={'bold'} m={5}>To</Text>
        <DatePicker
         style={{ height: 35}}
         name='End date'
         width='20%'
          type='date'
          value={endDate}
          onChange={setEndDate}
          placeholder='End Date'
        />

  <Button loading={loadingDateFilter} loaderProps={{ type: 'dots', size: 18}} leftSection={<IconFilter size={20}/>} variant='filled' color='#000' onClick={filterData} style={{ marginInline: 10 }}>
          Filter Date
        </Button>
       </div>

<div>
<Button leftSection={<IconRotate size={20}/>} variant='outline' color='#000' loading={loadingRefresh} loaderProps={{type: 'dots', size: 18}} onClick={handleRefresh} style={{ marginInline: 10 }}>
          Refresh
        </Button>
       <Button leftSection={<IconCalendarCancel size={20}/>} variant='filled' color='#000' onClick={handleFilterReset} style={{ marginInline: 10 }}>
          Reset Filter
        </Button>
</div>
     
       </div>
   
        
      
        <div className='tableOnMobile'>
        <TransactionsTable showLoadMoreButton loadMoreData={handleLoadMoreData} showPageTotalAmount={true} data={transactions} dataType='deposit' />
         </div>
        </Paper>
       
     
    
    </>
  );
}
