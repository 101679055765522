import { Paper, Title, Text, Badge } from "@mantine/core";
import {
  IconArrowsExchange,
  IconMessage,
  IconUsers,
  IconUsersGroup,
} from "@tabler/icons-react";

import '../../styles/card.css'
import { Skeleton } from "antd";
import { useDailyTransactionsCountContext } from "../../context/TotalDailyTransactionCount";
import { useAgentsContext } from "../../context/agentsContext";
import { useCustomerDataContext } from "../../context/customersContext";
import { useOrgInfo } from "../../context/orgContext";

export default function Card() {
  const { dailyTransactionsCount } = useDailyTransactionsCountContext()
  const {agents} = useAgentsContext()
  const {customers, loading} = useCustomerDataContext()
  const { sms } = useOrgInfo()
   //low sms balance
   const lowSMSBalance = 10

  const dashboardData = [
    {
      title: "Employees",
      number: agents?.length,
      icon: <IconUsers size={24} color="#5b5b5b" />,
      indicator: "Employees",
    },
    {
      title: "SMS Balance",
      number: sms.balance,
      icon: <IconMessage size={24} color= { sms.balance <= lowSMSBalance ? 'red': "#5b5b5b"} />,
      indicator: "Total SMS Balance",
    },
    {
      title: "Customers",
      number: customers.length,
      icon: <IconUsersGroup size={24} color="#5b5b5b" />,
      indicator: "Total customers"
    },
    {
      title: "Transaction Count",
      number: dailyTransactionsCount,
      icon: <IconArrowsExchange size={24} color="#5b5b5b" />,
      indicator: "Daily Total",
      // indicator: new Date().toDateString(),
    },
  ];


  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        // flexWrap: "wrap",
      }}
      className="cardContainer"
    >
      {dashboardData.map((item, index) => {
        return (
            <Paper withBorder p="lg" radius="md"
             className="card"
              key={index}
              bg={"#fff"}
              h={140}
              mb={10}
              w={310}
              style={{ display: "flex", borderColor: '#dedede', justifyContent: "space-between", color:'#000', borderRadius: 8}}   
              //  boxShadow: '0 1px 10px rgba(0, 0, 0, 0.1)'
            >
              {loading ? <Skeleton active paragraph={{ rows: 1, width: '60%' }} style={{ lineHeight: '20px', margin: '10px 0' }} />
              :  <div>
                <Text style={{ fontSize: 13, fontWeight: 'bold', marginBottom: 10, color: '#5b5b5b'}}>{item.title.toUpperCase()}</Text>
                <Title style={{ color: item.title === 'SMS Balance' && sms.balance <= lowSMSBalance ? 'red' : ''}} order={2}>{item.number}</Title>     
                {item.title === 'SMS Balance' && sms.balance <= lowSMSBalance && <small style={{ color: 'red', fontSize: 10}}>SMS Low: Top up</small> }      
              </div>}
             
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "flex-end"
                }}
              >
                {item.icon}
                <Badge variant="light" color={ item.title === 'SMS Balance' && sms.balance <= lowSMSBalance ? 'red' : "#4b4b4b"}>
                  {item.indicator}
                </Badge>
              </div>
            </Paper>
        );
      })}
    </div>
  );
}
