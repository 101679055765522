

import {  Avatar, Menu, MenuTarget, Text } from '@mantine/core'
import { useOrgInfo } from '../../context/orgContext'

export default function CompanyLogo() {
    const { companyName } = useOrgInfo()
  return (
    //removeOnMobile
    <div className="" style={{  display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 10}}>
    <Menu withArrow={true}>
    <Menu.Target>
    <Avatar variant='light' style={{ cursor: 'pointer'}} size={50} radius={'xs'} key={companyName || 'DigiCollect'} name={companyName || 'DigiCollect'} color="initials"/>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item style={{ display: 'flex', flexDirection: 'row'}}>
        <Avatar variant='filled' size={30} radius={'xs'} key={companyName || 'DigiCollect'} name={companyName || 'DigiCollect'} color="initials"/>
          <Text style={{ fontWeight: 'bolder'}}>{companyName}</Text>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
    </div>
  )
}
